import {
  RESET_PASSWORD_CHECK_PHONE,
  RESET_PASSWORD_SMS,
  RESET_PASSWORD_CHANGE,
  RESET_PASSWORD_CHECK_PASSPORT,
} from '@/helpers/js/urls';
import router from '@/router/Router';
import store from '../index';

const state = {
  smsErrorTimer: null,
};
const getters = {};
const mutations = {
  SET_ERROR_TIMER(state, data) {
    state.smsErrorTimer = data;
  },
};
const actions = {
  CHECK_PHONE: async ({
    dispatch,
    commit,
  }, data) => {
    try {
      const response = await dispatch('API', {
        method: 'post',
        url: RESET_PASSWORD_CHECK_PHONE,
        body: data,
      }, { root: true });
      if (!response) return;
      commit('Auth/ACCESS_KEY', response.data.data.access_key, { root: true });
      if (response.data.data.pass_passport_check) {
        return response?.data?.data;
      }
      localStorage.setItem('reset_passwordTimer', new Date().getTime());
      router.push({
        name: 'ResetSMS',
      });
    } catch (rej) {
      if (rej?.response?.status === 429) {
        commit('SET_ERROR_TIMER', rej?.response?.data?.data?.message[0]);
        commit('CLEAR_ERRORS', {}, { root: true });
      }
    }
  },
  CHECK_PASSPORT: async ({
    dispatch,
    commit,
  }, data) => {
    try {
      const response = await dispatch('API', {
        method: 'post',
        url: RESET_PASSWORD_CHECK_PASSPORT,
        body: data,
      }, { root: true });
      if (!response) return;
      // commit('Auth/ACCESS_KEY', response.data.data.access_key, { root: true });
      localStorage.setItem('reset_passwordTimer', new Date().getTime());
      router.push({
        name: 'ResetSMS',
      });
    } catch (rej) {
      if (rej?.response?.status === 429) {
        commit('SET_ERROR_TIMER', rej?.response?.data?.data?.message[0]);
        commit('CLEAR_ERRORS', {}, { root: true });
      }
    }
  },
  SMS: async ({
    dispatch,
    rootState,
  }, data) => {
    await dispatch('API', {
      method: 'post',
      url: RESET_PASSWORD_SMS,
      body: data,
    }, { root: true });

    if (rootState.errors.length > 0) {
      for (const error in rootState.errors) {
        if (rootState.errors[error].code === 'sms_passed') {
          router.push({
            name: 'NewPassword',
          });
        } else if (rootState.errors[error].code === 'expired_sms_code') {
          router.push({
            name: 'Registration',
          });
        }
      }
    } else {
      if (localStorage.getItem('reset_passwordTimer')) {
        localStorage.removeItem('reset_passwordTimer');
      }

      router.push({
        name: 'NewPassword',
      });
    }
  },
  NEW_PASSWORD: async ({
    dispatch,
  }, data) => {
    const response = await dispatch('API', {
      method: 'post',
      url: RESET_PASSWORD_CHANGE,
      body: data,
    }, { root: true });
    if (!response) return;

    store.dispatch('Auth/AUTH_SUCCESS', response.data.data.token).then(() => {
      store.dispatch('Auth/SUCCESS_ENTRY');
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
