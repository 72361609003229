import Vue from 'vue';
import Vuex from 'vuex';
import { isEmpty } from '@/helpers/functions';

import {
  SERVER,
  DIRECTORY_DATA,
  RESEND_SMS,
  BORROWER_DATA,
  FEEDBACK_THEMES,
  OFFICES,
  HELP_TOPICS,
  ADVERTISING_SETTINGS,
  INITIAL_LK_LEAD,
  INITIAL_LK_LEAD_PERSONAL_INFO,
  GRAYLOG_COLLECT_DATA_URL,
} from '@/helpers/js/urls';
import consts from '@/helpers/consts';

import { addApiLog } from '@/helpers/sentry';

import GetLoan from './modules/getLoan';
import Registration from './modules/registration';
import ResetPassword from './modules/resetPassword';
import Identification from './modules/identification';
import MyLoans from './modules/myLoans';
import Payment from './modules/payment';
import Profile from './modules/profile';
import Insurance from './modules/insurance';
import Auth from './modules/auth';
import LoanRequest from './modules/loanRequest';

Vue.use(Vuex);

export const defaultStore = ({
  state: {
    directoryData: {},
    borrowerData: {},
    identificationStatusMessage: null,
    preloader: false,
    errors: [],
    offices: null,
    server: SERVER,
    googleToken: null,
    requestStatus: null,
    senderEngine: localStorage.getItem('SENDER_ENGINE'),
    refreshPageModalVisible: false,
  },
  getters: {
    offices: state => {
      if (!state.offices) return;
      return state.offices.filter(item => item.city !== '' && item.city !== null);
    },
    confirmationText: state => (state.senderEngine && state.senderEngine.toLocaleLowerCase() === 'sms'
      ? consts.SMS_SENDER_TEXT
      : consts.CALL_SENDER_TEXT),
    getRegionDadataNameById: (state, getters) => id => {
      const item = getters.regions?.find(region => region.id === id);
      return item && item.dadataName;
    },
    isBorrowerDataEmpty: state => isEmpty(state.borrowerData),
    isDirectoryDataEmpty: state => isEmpty(state.directoryData),
    // костыль, отдает регионы с названиями региона для дадаты
    regions: state => state.directoryData?.region_info?.map(region => {
      region.dadataName = region?.name.replace(/край|(автономная)* область| город|Республика|республика|автономный округ|/g, '');

      if (region?.name.includes('Саха')) {
        region.dadataName = 'Саха /Якутия/';
      }

      if (region?.name.includes('Югра')) {
        region.dadataName = 'Ханты-Мансийский Автономный округ - Югра';
      }

      if (region?.name.includes('Кемеровская')) {
        region.dadataName = 'Кемеровская область - Кузбасс';
      }

      if (region?.name.includes('Чувашская')) {
        region.dadataName = 'Чувашская республика';
      }

      return region;
    }),
    editLoanError: (state, getters) => {
      if (getters.isBorrowerDataEmpty) return;

      if (state.borrowerData.identification_status === 0 || state.borrowerData.identification_status === -1) {
        return 'Сохранение анкеты станет доступно после успешного прохождения идентификации.<br> <a href="/identification/" class="link-red">Пройти идентификацию<a/>';
      } if (state.borrowerData.identification_status === 1) {
        return 'Дождитесь результатов прохождения идентификации.';
      } if (state.borrowerData.active_loan) {
        return 'У Вас есть активный заём или заявка на получение займа.<br> Оплатить заём Вы можете по кнопке <a href="/my-loans/" class="link-red">Погасить заём<a/> выбрав операцию "Банковской картой".';
      }
    },
    identificationStatusMessage: (state, getters) => {
      if (getters.isBorrowerDataEmpty) return;
      const status = state.borrowerData.identification_status;
      const identificationInfo = {
        iconPath: null,
        title: null,
        icon: null,
      };

      switch (status) {
      case -1:
        identificationInfo.iconPath = require('@/assets/images/icons/unverified.svg');
        identificationInfo.title = 'Ошибка идентификации';
        break;
      case 0:
        identificationInfo.iconPath = require('@/assets/images/icons/unverified.svg');
        identificationInfo.title = 'Не идентифицирован';
        break;
      case 1:
        identificationInfo.icon = ['fas', 'sync-alt'];
        identificationInfo.iconClass = 'icon-success-disabled';
        identificationInfo.title = 'В процессе';
        break;
      case 2:
        identificationInfo.iconPath = require('@/assets/images/icons/verified-active.svg');
        identificationInfo.title = 'Идентифицирован';
        break;
      case 3:
        identificationInfo.iconPath = require('@/assets/images/icons/verified-active.svg');
        identificationInfo.title = 'Идентифицирован';
        break;
      default:
        break;
      }

      return identificationInfo;
    },
    defaultSenderEngine: state => state.directoryData.sender_engine,
  },
  mutations: {
    SET_SENDER_ENGINE: (state, value) => {
      state.senderEngine = value;
    },
    DIRECTORY_DATA: (state, data) => {
      state.directoryData = data;
    },
    REQUEST_STATUS: (state, data) => {
      state.requestStatus = data;
    },
    OFFICES: (state, data) => {
      state.offices = data;
    },
    BORROWER_DATA: (state, data) => {
      state.borrowerData = data;
    },
    PRELOADER: (state, data) => {
      state.preloader = data || false;
    },

    CLEAR_ERRORS: state => {
      /*
      * Нужен для того что бы можно было при вводе значений в поля в формах можно было сразу отчищать оишбки.
      * */
      state.errors = [];
    },
    ERRORS: (state, { data, showEmptyErrors = true }) => {
      state.errors = [];
      if (!data) return;
      for (const error in data) {
        if (data[error].hasOwnProperty('message') && (showEmptyErrors || !!data[error].message[0])) {
          state.errors.push(data[error]);
        }
      }
    },
    UPDATE_ERROR: (state, data) => {
      state.refreshPageModalVisible = data;
    },
  },
  actions: {
    API({ commit, state, getters }, {
      method, url, body = null, responseType, headers = {}, preloader = false, showEmptyErrors = true,
    }) {
      commit('CLEAR_ERRORS');
      commit('REQUEST_STATUS', 'pending');

      if (preloader) {
        commit('PRELOADER', preloader);
      }

      return new Promise((resolve, reject) => {
        (method === 'get' ? Vue.prototype.$http.get(url, { headers, responseType }) : Vue.prototype.$http[method](url, body, { headers, responseType }))
          .then(res => {
            resolve(res);
          }).catch(e => {
            addApiLog({
              state,
              getters,
              error: e,
              request: {
                url, method, body, headers,
              },
            });
            if (!e.response) return;

            const data = e.response.data && e.response.data.data;

            if (data && data.hasOwnProperty('message') && !data.message.hasOwnProperty('message')) {
              commit('ERRORS', { data: { error: data } });
            } else if (data) {
              commit('ERRORS', { data, showEmptyErrors });
            } else {
              commit('ERRORS', { data: [{ message: ['Извините, произошла ошибка на сервере. Обратитесь в тех. поддержку'] }] });
            }
            reject(e);
          })
          .then(() => {
            if (preloader) {
              commit('PRELOADER');
            }
            commit('REQUEST_STATUS', 'finish');
          });
      });
    },
    RESEND_SMS: async ({
      dispatch,
    }, data) => {
      await dispatch('API', {
        method: 'post',
        url: RESEND_SMS,
        body: data,
      });
    },
    HELP_TOPICS: async ({
      dispatch,
    }) => {
      const response = await dispatch('API', {
        method: 'get',
        url: HELP_TOPICS,
      });

      if (!response) return;

      return response;
    },
    GET_ADVERTISING_SETTINGS: async ({
      dispatch,
    }) => dispatch('API', {
      method: 'get',
      url: ADVERTISING_SETTINGS,
    }),
    SEND_ADVERTISING_SETTINGS: async ({
      dispatch,
    }, data) => {
      await dispatch('API', {
        method: 'post',
        url: ADVERTISING_SETTINGS,
        body: data,
      });
    },
    FEEDBACK_THEMES: async ({
      dispatch,
    }, data) => dispatch('API', {
      method: 'get',
      url: FEEDBACK_THEMES,
      body: data,
    }),
    FEEDBACK_CREATE: async ({
      dispatch,
    }, data) => dispatch('API', {
      method: 'post',
      url: FEEDBACK_THEMES,
      body: data,
      preloader: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
    GOOGLE_TOKEN: ({ state }, data) => {
      state.googleToken = data;
    },
    OFFICES: async ({
      dispatch,
      commit,
    }) => {
      const response = await dispatch('API', {
        method: 'get',
        url: OFFICES,
      });

      if (!response) return;

      commit('OFFICES', response.data.data.offices);
    },
    COMMON_BORROWER_DATA: async ({
      commit,
      dispatch,
    }) => {
      try {
        const response = await dispatch('API', {
          method: 'get',
          url: BORROWER_DATA,
        });
        if (!response) return;
        if (response.data?.data) {
          commit('BORROWER_DATA', response.data?.data);
          commit('Identification/setIdentificationStatus', response.data.data.identification_status);
        }
      } catch (rej) {
        // обрабатываем 401 тут потому что запрос может отправляться еще до инициализации интерсептеров
        if (rej?.response?.status === 401) {
          await dispatch('Auth/ENTRY_ERROR');
        }
      }
    },
    GET_DIRECTORY_DATA: async ({
      commit,
      dispatch,
    }) => {
      const response = await dispatch('API', {
        method: 'get',
        url: DIRECTORY_DATA,
        headers: {
          Accept: 'application/json',
          Authorization: '',
        },
      });

      if (!response) return;

      if (response?.data?.data) {
        commit('DIRECTORY_DATA', response.data.data);
        if (localStorage.getItem('SENDER_ENGINE')) {
          commit('SET_SENDER_ENGINE', localStorage.getItem('SENDER_ENGINE'));
        } else if (response.data?.data?.sender_engine) {
          commit('SET_SENDER_ENGINE', response.data.data.sender_engine);
        }
      }
    },
    SET_SENDER_ENGINE({ commit }, payload) {
      commit('SET_SENDER_ENGINE', payload);
    },
    async GET_LEAD_TOKEN({
      dispatch,
    }, payload) {
      const lead_token = localStorage.getItem('lead_token');

      if (!lead_token) {
        const { data: { data } } = await dispatch('API', {
          method: 'post',
          body: payload,
          url: INITIAL_LK_LEAD,
        }, { root: true });

        localStorage.setItem('lead_token', data.session_token);
      }
    },
    async PUT_LEAD_PARAMS({
      dispatch,
    }, payload) {
      await dispatch('API', {
        method: 'put',
        body: payload,
        headers: {
          Authorization: `token ${localStorage.getItem('lead_token')}`,
        },
        url: INITIAL_LK_LEAD,
      }, { root: true });
    },
    async SEND_LEAD_PHONE_NUMBER({
      dispatch,
    }, { payload = null, method }) {
      const response = await dispatch('API', {
        method,
        body: payload,
        url: INITIAL_LK_LEAD_PERSONAL_INFO,
        headers: {
          Authorization: `token ${localStorage.getItem('lead_token')}`,
        },
      }, { root: true });

      if (!response) return;

      return response;
    },
    async SAVE_LEAD_PHONE_NUMBER({
      dispatch,
    }, payload) {
      const { data: { data } } = await dispatch('SEND_LEAD_PHONE_NUMBER', { method: 'get' });

      if (data) {
        await dispatch('SEND_LEAD_PHONE_NUMBER', { method: 'put', payload: data });
      } else {
        await dispatch('SEND_LEAD_PHONE_NUMBER', { method: 'post', payload });
      }
    },
    async GRAYLOG_COLLECT_DATA({
      dispatch,
    }, payload) {
      try {
        await dispatch('API', {
          method: 'post',
          body: payload,
          url: GRAYLOG_COLLECT_DATA_URL,
          headers: {
            Authorization: '',
          },
        }, { root: true });
      } catch (e) {
        console.log(e);
      }
    },
  },
  modules: {
    GetLoan,
    MyLoans,
    Payment,
    Registration,
    ResetPassword,
    Profile,
    Auth,
    LoanRequest,
    Identification,
    Insurance,
  },
});

export default new Vuex.Store(defaultStore);
