import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import ru from '../helpers/i18n/ru';

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#ce0f0f',
        secondary: '#102637',
        accent: '#82B1FF',
        error: '#f47d06',
        info: '#33b5e5',
        success: '#00C851',
        warning: '#ffbb33',
        white: '#ffffff',
        blue: '#5080A5',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
  lang: {
    locales: { ru },
    current: 'ru',
  },
};

export default new Vuetify(opts);
