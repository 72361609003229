<template>
  <div
    ref="submitWrapper"
    class="form-submit"
    :class="{'form-submit_float-error': theme === 'profile'}"
  >
    <template v-if="theme === 'profile'">
      <div class="form-submit__wrapper">
        <button
          v-if="isSubmitClicked"
          class="btn btn-disabled"
        >
          <slot />
        </button>
        <button
          v-else-if="borrowerData.edit_profile && !disabled"
          class="btn btn-red"
          type="submit"
          @click="showErrorMessage"
        >
          <slot />
        </button>
        <button
          v-else
          class="btn btn-disabled"
          type="button"
        >
          Недоступно для сохранения
        </button>
      </div>
    </template>
    <template v-else>
      <div class="form-submit__wrapper">
        <button
          v-if="disabled || isSubmitClicked"
          class="btn btn-disabled"
          type="button"
        >
          <slot />
        </button>
        <button
          v-else
          type="submit"
          :class="'btn btn-red ' + className"
          @click="showErrorMessage"
        >
          <slot />
        </button>
      </div>
    </template>
    <div
      v-if="error || errors.length > 0"
      class="form-submit__error"
    >
      <template v-if="theme === 'profile' && errors.length > 0">
        <ErrorBox />
      </template>
      <template v-else>
        Вы заполнили не все поля или ввели некорректные данные. Проверьте и попробуйте еще раз.
      </template>
    </div>
  </div>
</template>

<script>
import {
  mapState,
} from 'vuex';

import ErrorBox from '@/components/errors/ErrorBox.vue';

export default {
  name: 'BaseSubmit',
  components: {
    ErrorBox,
  },
  props: {
    theme: {
      type: String,
      default: 'common',
    },
    disabled: Boolean,
    className: {
      type: String,
      default: '',
    },
    isSubmitClicked: {
      type: Boolean,
      default: false,
    },
    error: Boolean,
  },
  computed: {
    ...mapState({
      borrowerData: state => state.borrowerData,
      errors: state => state.errors,
      requestStatus: state => state.requestStatus,
    }),
  },
  watch: {
    error() {
      if (!this.error && this.errors.length === 0) {
        this.hideErrorMessage();
      }
    },
    errors() {
      if (this.errors.length > 0) this.showErrorMessage();
    },
  },
  methods: {
    showErrorMessage() {
      const wrapper = this.$refs.submitWrapper;

      setTimeout(() => {
        if (this.error || this.errors.length > 0) {
          wrapper.classList.add('show-error');
        }
      }, 0);
    },
    hideErrorMessage() {
      const wrapper = this.$refs.submitWrapper;

      if (wrapper.classList.contains('show-error')) {
        wrapper.classList.remove('show-error');
      }
    },
  },
};
</script>

<style scoped lang="scss">
   .form-submit {
     transition: all .2s;
     background-color: transparent;
     z-index: 7;

     &.show-error {
       @media screen and (max-width: 768px) {
         &.form-submit_float-error {
           background-color: #fff;
           padding: 16px;
           border-radius: 16px;
           box-shadow: 0 0 15px rgba(1, 23, 40, 0.1);
         }

         .form-submit__error {
           display: block;
         }
       }
     }

     &__error {
       color: $color-orange;
       max-width: 330px;
       margin: 10px auto 0;
       display: none;
     }

     @media screen and (max-width: 480px) {
       button {
         width: 100%;
       }
     }
   }
</style>
