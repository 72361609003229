<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #default>
      <slot name="default" />
    </template>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseButton',
};
</script>

<style scoped lang="scss">

</style>
