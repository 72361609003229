export default {
  COMPANY_PHONE: '8 800 700 08 08',
  COMPANY_HELP_EMAIL: 'help@dengabank.ru',
  SESSION_EXPIRED: 'Сессия устарела. Проверка кода невозможна, начните заново.',
  AUTH_EXPIRED: 'Время авторизации истекло',
  USER_EXIST: 'Пользователь с таким телефоном уже зарегистрирован',
  THANKS_FOR_FEEDBACK: 'Спасибо за обращение!',
  SUCCESS_PAYMENT: 'Ваш платеж успешно получен. Обработка платежа происходит НЕ моментально. Информация о зачислении отобразится в Личном кабинете в течение 3-24 часов.',
  REJECTED_PAYMENT: 'Платёж отклонён. Пожалуйста, обратитесь в Банк, выпустивший Вашу карту.',
  CHANGE_PHONE_SUCCESS: 'Ваш номер успешно изменен!',
  IDENTIFICATION: {
    STATUSES: {
      ERROR: 'Ошибка идентификации',
      IN_PROCESS: 'В процессе',
      SIMPLE_IDENTIFICATION: 'Упрощенно идентифицирован',
      ADVANCED_IDENTIFICATION: 'Идентифицирован',
      NO_IDENTIFICATION: 'Не идентифицирован',
    },
  },
  DELETE_INSURANCE_CONFIRM_TEXT: 'При отказе от страхования ПОЛНАЯ ответственность за выплату ЗАЙМА лежит на вас. Вы уверены что хотите отказаться?',
  DELETE_SMS_CONFIRM_TEXT: 'SMS-уведомление заранее сообщит вам дату платежа и предоставит короткую ссылку на его оплату. Вы уверены, что хотите отказаться?',
  GENDERS: {
    DEFAULT: 'Не выбрано',
    MALE: 'Мужской',
    FEMALE: 'Женский',
  },
  PAYMENT: {
    TYPES: {
      CASH: 'Наличными',
      CARD: 'На карту',
      COMMON: 'На карту / Наличными',
    },
  },
  ERROR: 'Произошла ошибка, повторите попытку.',
  PROFILE: {
    CANT_EDIT_PROFILE: 'Редактирование анкеты недоступно',
    HIGH_FILE_WEIGHT: 'Загружаемый файл не должен превышать 15 мб',
    INCORRECT_FILE: 'Недопустимый формат файла',
    FILES_SIZE_TOO_LARGE: 'Превышен максимальный объём прикрепляемых файлов',
    FILE_ERROR: 'Не удалось загрузить фото. Попробуйте загрузить файл в формате jpeg, jpg, png размером до 15мб.',
  },
  GET_LOAN: {
    DONT_CALL_TO_USER: 'К сожалению, мы не смогли до Вас дозвониться. Как будете готовы ответить на звонок сотрудника для уточнения данных, указанных в Анкете, нажмите на кнопку "Запросить звонок."',
  },
  noDataText: 'Данные не найдены',
  mandarinErrors: {
    // Цифры означают код ошибки мандарина. В некоторых местах у нескольких кодов надо выводить один и тот же текст.
    '-2': 'Данные введены неверно.',
    14: 'Введена несуществующая карта.',
    54: 'Неверно введен срок действия банковской карты.',
    83: 'Произошла ошибка, попробуйте еще раз.',
    91: 'Эмитент недоступен.',
    96: 'Произошла ошибка, попробуйте еще раз.',
    1666: 'Проверьте корректность введенных данных.',
    400: 'Проверьте корректность введенных данных.',
    '05': 'Отказ эмитента без указания причины - необходимо обращаться в банк, выпустивший карту.',
    17: 'Отказать, отклонено пользователем карты.',
    '-19': 'Не пройдена авторизация.',
    41: 'Утерянная карта - необходимо обращаться в банк, выпустивший карту.',
    43: 'Украденная карта - необходимо обращаться в банк, выпустивший карту.',
    51: 'На счете недостаточно средств.',
    57: 'Недопустимый тип операции для данного типа карты.',
    61: 'Превышение максимальной суммы операции или количества попыток списания для данной карты.',
    62: 'Заблокированная карта.',
    65: 'Превышение максимального количества операции для данной карты.',
    66: 'Установлена блокировка банком для данной карты.',
    88: 'Отказ, некорректно введен код CVC2.',
    '-3': 'Нет ответа от эмитента/эквайера.',
    defaultMessage: 'Произошла ошибка, попробуйте еще раз.',
  },
  best2PayErrors: {
    2: 'Неверный срок действия Банковской карты.',
    3: 'Платёж отклонён. Пожалуйста, обратитесь в Банк, выпустивший Вашу карту.',
    4: 'Платёж отклонён. Пожалуйста, обратитесь в Банк, выпустивший Вашу карту.',
    5: 'Платёж отклонён. Пожалуйста, обратитесь в Банк, выпустивший Вашу карту.',
    6: 'Недостаточно средств на счёте Банковской карты.',
    12: 'Указано неверное значение секретного кода карты.',
    defaultMessage: 'Произошла ошибка, попробуйте еще раз.',
  },
};
