<template>
  <default-modal
    :adaptive="true"
    :max-width="600"
    class=""
    height="auto"
    name="google-form-pool"
  >
    <div class="google-form-pool">
      <div class="google-form-pool__content">
        <BaseForm
          ref="form"
          :validator="$v"
          type="googlePool"
          @submit="onSubmit"
        >
          <template #errors>
            <form-errors :validator="$v.fields" />
          </template><template #body>
            <div class="form-fields">
              <div class="form-fields__title">
                Какой размер займа для вас предпочтителен?
              </div><p>Приглашаем вас принять участие в опросе. Ваши ответы помогут нашей компании  повысить качество сервиса и сделать наши продукты более привлекательными!</p><form-field>
                <div class="form-field-radio">
                  <div class="form-field-radio__list">
                    <div class="form-field-radio__item">
                      <div class="custom-radio">
                        <label class="custom-radio__label"><input
                          id="m"
                          v-model="fields.pool.value"
                          class="custom-radio__input"
                          name="entry.1408878408"
                          type="radio"
                          value="до 5000 руб."
                        ><div class="custom-radio__text">до 5000 руб.</div></label>
                      </div>
                    </div><div class="form-field-radio__item">
                      <div class="custom-radio">
                        <label class="custom-radio__label"><input
                          id="w"
                          v-model="fields.pool.value"
                          class="custom-radio__input"
                          name="entry.1408878408"
                          type="radio"
                          value="5000 - 15 000 руб."
                        ><div class="custom-radio__text">5000 - 15 000 руб.</div></label>
                      </div>
                    </div><div class="form-field-radio__item">
                      <div class="custom-radio">
                        <label class="custom-radio__label"><input
                          id="un"
                          v-model="fields.pool.value"
                          class="custom-radio__input"
                          name="entry.1408878408"
                          type="radio"
                          value="15 000 - 30 000 руб."
                        ><div class="custom-radio__text">15 000 - 30 000 руб.</div></label>
                      </div>
                    </div><div class="form-field-radio__item">
                      <div class="custom-radio">
                        <label class="custom-radio__label"><input
                          id="four"
                          v-model="fields.pool.value"
                          class="custom-radio__input"
                          name="entry.1408878408"
                          type="radio"
                          value="30 000 - 50 000 руб."
                        ><div class="custom-radio__text">30 000 - 50 000 руб.</div></label>
                      </div>
                    </div><div class="form-field-radio__item">
                      <div class="custom-radio">
                        <label class="custom-radio__label"><input
                          id="five"
                          v-model="fields.pool.value"
                          class="custom-radio__input"
                          name="entry.1408878408"
                          type="radio"
                          value="50 000 - 100 000 руб."
                        ><div class="custom-radio__text">50 000 - 100 000 руб.</div></label>
                      </div>
                    </div>
                  </div>
                </div>
              </form-field>
            </div>
          </template>
          <template #footer>
            <base-submit>Отправить</base-submit>
          </template>
        </BaseForm>
      </div>
    </div>
  </default-modal>
</template>

<script>
import axios from 'axios';
import {
  required,
} from 'vuelidate/lib/validators';
import modals from '@/helpers/mixins/modals';
import formHandlers from '@/helpers/mixins/formHandler';
import DefaultModal from '@/helpers/modals/DefaultModal.vue';

export default {
  name: 'GoogleFormPool',
  components: { DefaultModal },
  mixins: [formHandlers, modals],
  data() {
    return {
      fields: {
        pool: {
          value: null,
          validations: {
            chooseOne: required,
          },
        },
        other: {
          value: null,
        },
      },
    };
  },
  methods: {
    onSubmit() {
      const vm = this;
      vm.$v.$touch();

      if (vm.$v.$invalid) return;
      const { form } = vm.$refs;
      const formData = new FormData(form.$el);
      const url = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSdRr102YcI9SQJ5iklBV-ynRftpUJHZbeSn1evEONCHguI8Ng/formResponse?embedded=true';

      if (this.fields.pool.value === '__other_option__') {
        formData.append('entry.1408878408.other_option_response', this.fields.other.value);
      }

      axios.post(url, formData);

      localStorage.setItem('isVoteInGooglePool0901', true);
      vm.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
   .google-form-pool {
      padding: 0 15px;

      &__close {
         cursor: pointer;
      }

      &__close {
         color: #fff;
         text-align: right;
         font-size: 20px;
      }

      .form-field {
         max-width: 100%;
      }

      .custom-radio__label {
         justify-content: flex-start;
         align-content: flex-start;
      }

      .form-fields__title {
         font-size: 24px;
         margin-bottom: 15px;
      }

      .form-field-radio {
         display: block;

         &__item {
            margin-bottom: 8px;
            margin-right: 0;

            &:last-child {
               margin-bottom: 0;
            }
         }

         .custom-radio__text {
            text-align: left;

            &:after {
               top: 5px;
               transform: translateY(0);
            }

            &:before {
               top: 10px;
               transform: translateY(0);
            }
         }

         &__list {
            display: block;
         }
      }
   }
</style>
