<template>
  <div>
    <img
      v-if="identificationStatus !== 1"
      :src="getIconImagePath"
    >
    <fai
      v-else
      :icon="['fas', 'sync-alt']"
      class="icon-success-disabled pointer"
      @click="refreshIdentification"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'IdentificaionStatusIcon',
  computed: {
    ...mapState('Identification', {
      identificationStatus: state => state.identificationStatus,
    }),
    getIconImagePath() {
      switch (this.identificationStatus) {
      case -1:
        return require('@/assets/images/icons/unverified.svg');
      case 2:
        return require('@/assets/images/icons/verified-active.svg');
      case 3:
        return require('@/assets/images/icons/verified-active.svg');
      default:
        return require('@/assets/images/icons/unverified.svg');
      }
    },
  },
  methods: {
    refreshIdentification(e) {
      const refreshIcon = e.target;
      if (!refreshIcon.classList.contains('refresh')) {
        refreshIcon.classList.add('refresh');
        this.$store.dispatch('COMMON_BORROWER_DATA').then(() => {
          setTimeout(() => {
            refreshIcon.classList.remove('refresh');
          }, 1000);
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
