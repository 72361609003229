import { routingLazyLoad } from '@/helpers/functions';

const loanRequestRoutes = [
  {
    path: '/lp/',
    component: routingLazyLoad('LoanRequestMain', 'LoanRequest'),
    meta: {
      title: '',
    },
    children: [
      {
        path: 'phone',
        name: 'LoanRequestPhone',
        component: routingLazyLoad('LoanRequestPhone', 'LoanRequest'),
        meta: {
          title: '',
          progress: 0,
          calculator: true,
          calcResult: false,
        },
      },
      {
        path: 'personal',
        name: 'LoanRequestPersonal',
        component: routingLazyLoad('LoanRequestPersonal', 'LoanRequest'),
        meta: {
          title: '',
          progress: 0,
          calculator: true,
          calcResult: false,
        },
      },
      {
        path: 'passport',
        name: 'LoanRequestPassport',
        component: routingLazyLoad('LoanRequestPassport', 'LoanRequest'),
        meta: {
          title: '',
          progress: 20,
          calculator: true,
          calcResult: true,
          calcSaveButton: true,
        },
      },
      {
        path: 'address',
        name: 'LoanRequestAddress',
        component: routingLazyLoad('LoanRequestAddress', 'LoanRequest'),
        meta: {
          title: '',
          progress: 50,
          calculator: true,
          calcResult: true,
          calcSaveButton: true,
        },
      },
      {
        path: 'additionalinfo',
        name: 'LoanRequestAdditionalInfo',
        component: routingLazyLoad('LoanRequestAdditionalInfo', 'LoanRequest'),
        meta: {
          title: '',
          progress: 80,
          calculator: true,
          calcResult: false,
          calcSaveButton: false,
        },
      },
      {
        path: 'job',
        name: 'LoanRequestJob',
        component: routingLazyLoad('LoanRequestJob', 'LoanRequest'),
        meta: {
          title: '',
          progress: 80,
          calculator: true,
          calcResult: true,
          calcSaveButton: true,
        },
      },
      {
        path: 'fullinfo',
        name: 'LoanRequestFullInfo',
        component: routingLazyLoad('LoanRequestFullInfo', 'LoanRequest'),
        meta: {
          title: '',
          progress: 90,
          calculator: true,
          calcResult: true,
          calcSaveButton: true,
        },
      },
      {
        path: 'card',
        name: 'LoanRequestCard',
        component: routingLazyLoad('LoanRequestCard', 'LoanRequest'),
        meta: {
          title: '',
          progress: 100,
          hideProgressLine: true,
          calculator: false,
          calcResult: false,
        },
      },
      {
        path: 'photo',
        name: 'LoanRequestPhoto',
        component: routingLazyLoad('LoanRequestPhoto', 'LoanRequest'),
        meta: {
          title: '',
          progress: 100,
          hideProgressLine: true,
          calculator: false,
          calcResult: false,
        },
      },
      {
        path: 'password',
        name: 'LoanRequestPassword',
        component: routingLazyLoad('LoanRequestPassword', 'LoanRequest'),
        meta: {
          title: '',
          progress: 100,
          hideProgressLine: true,
          calculator: false,
          calcResult: false,
        },
      },
      {
        path: 'error',
        name: 'LoanRequestErrorPage',
        component: routingLazyLoad('LoanRequestErrorPage', 'LoanRequest'),
      },
    ],
  },
];

export default loanRequestRoutes;
