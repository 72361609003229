import Vue from 'vue';
import { replaceString } from '@/helpers/functions';
import {
  INITIAL,
  INITIAL_PERSONAL_INFO,
  INITIAL_PASSPORT,
  INITIAL_ADDRESS,
  INITIAL_ADDITIONAL_INFO,
  INITIAL_LOAN_SUM,
  INITIAL_LOAN_PERIOD,
  INITIAL_LOAN_OPTIONS,
  INITIAL_LOAN_PHOTO,
  INITIAL_LOAN_FINALIZE_AGREE,
  INITIAL_LOAN_FINALIZE_CODE,
  INITIAL_LOAN_FINALIZE_PASSWORD,
  INITIAL_RESEND_SMS,
  INITIAL_LOAN_ESIA_ADDITIONAL_INFO,
} from '@/helpers/js/urls';

const state = {
  error_notAvailable: false,
  error_personalData: false,
  error_passportData: false,
  error_empty_phone: false,
  error_phone: false,
  period_requested: 15,
  summ_requested: 8000,
  matched_ls_id: null,
  insurance_summ: null,
  sms_informing_price: null,
  insurance_company_name: null,
  calcChanged: false,
  personal: null,
  editorCalcVisible: false,
  uuid: null,
  errorTimer: null,
  isEsiaActive: false,
};
const getters = {
  NOTACTIVE_CHECK: state => state.error_notAvailable,
  PERSONALDATA_CHECK: state => state.error_personalData,
  PHONE_CHECK: state => state.error_phone,
  PASSPORT_CHECK: state => state.error_passportData,
  EMPTY_PHONE_CHECK: state => state.error_empty_phone,
  SUMM_REQ: state => state.summ_requested,
  PERIOD_REQ: state => state.period_requested,
  INSURANCE_SUMM: state => state.insurance_summ,
  SMS_INFORM: state => state.sms_informing_price,
  INS_COMPANY_NAME: state => state.insurance_company_name,
};
const mutations = {
  SET_ERROR_TIMER(state, data) {
    state.errorTimer = data;
  },
  SET_LOAN_FIELD(state, { key, value }) {
    state[key] = value;
  },
  SET_ERROR_PHONE(state) {
    state.error_phone = true;
  },
  SET_ERROR_PERSONALDATA(state) {
    state.error_personalData = true;
  },
  SET_ERROR_NOTACTIVE(state) {
    state.error_notAvailable = true;
  },
  CLEAR_TOKEN() {
    delete Vue.prototype.$http.defaults.headers.common.Authorization;
    localStorage.removeItem('session_token');
  },
  SET_ERROR_PASSPORT(state) {
    state.error_passportData = true;
  },
  SET_ERROR_EMPTY_PHONE(state) {
    state.error_empty_phone = true;
  },
  CLEAR_ERRORS(state) {
    state.error_empty_phone = false;
    state.error_personalData = false;
    state.error_phone = false;
    state.error_passportData = false;
  },
};
const actions = {
  SET_ERROR_TIMER({ commit }, payload) {
    commit('SET_ERROR_TIMER', payload);
  },
  CLEAR_TOKEN({ commit }) {
    commit('CLEAR_TOKEN');
  },
  SET_ERROR_PHONE({ commit }) {
    commit('CLEAR_ERRORS');
    commit('SET_ERROR_PHONE');
  },
  SET_ERROR_PERSONALDATA({ commit }) {
    commit('CLEAR_ERRORS');
    commit('SET_ERROR_PERSONALDATA');
  },
  SET_ERROR_NOTACTIVE({ commit }) {
    commit('CLEAR_ERRORS');
    commit('SET_ERROR_NOTACTIVE');
    commit('CLEAR_TOKEN');
  },
  SET_ERROR_PASSPORT({ commit }) {
    commit('CLEAR_ERRORS');
    commit('SET_ERROR_PASSPORT');
  },
  SET_ERROR_EMPTY_PHONE({ commit }) {
    commit('CLEAR_ERRORS');
    commit('SET_ERROR_EMPTY_PHONE');
  },
  SET_LOAN_FIELD({ commit }, payload) {
    commit('SET_LOAN_FIELD', payload);
  },
  CLEAR_ERRORS({ commit }) {
    commit('CLEAR_ERRORS');
  },
  async SEND_PERSONAL_INFO({
    dispatch,
    state,
  }, data) {
    if (data.patronymic === null) data.patronymic = '';
    data = {
      ...data,
      matched_ls_id: state.matched_ls_id,
      summ_requested: state.summ_requested,
      period_requested: state.period_requested,
    };

    dispatch('SET_LOAN_FIELD', { key: 'personal', value: data });

    try {
      return await dispatch('API', {
        method: 'post',
        body: data,
        url: INITIAL_PERSONAL_INFO,
        preloader: true,
        showEmptyErrors: false,
      }, { root: true });
    } catch (rej) {
      return rej.response.data;
    }
  },
  async SEND_PERSONAL_INFO_PUT({
    dispatch,
    state,
    commit,
  }, data) {
    if (data.patronymic === null) data.patronymic = '';
    data = {
      ...data,
      matched_ls_id: state.matched_ls_id,
      summ_requested: state.summ_requested,
      period_requested: state.period_requested,
    };

    dispatch('SET_LOAN_FIELD', { key: 'personal', value: data });

    try {
      return await dispatch('API', {
        method: 'put',
        body: data,
        url: INITIAL_PERSONAL_INFO,
        preloader: true,
      }, { root: true });
    } catch (rej) {
      if (rej?.response?.status === 429) {
        commit('SET_ERROR_TIMER', rej?.response?.data?.data?.message[0]);
        commit('CLEAR_ERRORS', {}, { root: true });
      } else {
        return rej.response.data;
      }
    }
  },
  async SEND_PASSPORT({
    dispatch,
  }, data) {
    try {
      return await dispatch('API', {
        method: 'post',
        body: data,
        url: INITIAL_PASSPORT,
        preloader: true,
      }, { root: true });
    } catch (rej) {
      return { data: rej.response.data, status: rej.response.status };
    }
  },
  async SEND_PASSPORT_PUT({
    dispatch,
  }, data) {
    try {
      return await dispatch('API', {
        method: 'put',
        body: data,
        url: INITIAL_PASSPORT,
        preloader: true,
      }, { root: true });
    } catch (rej) {
      return { data: rej.response.data, status: rej.response.status };
    }
  },
  async SEND_ADDRESSES({
    dispatch,
  }, data) {
    if (data.actual.flat === null) data.actual.flat = '';
    if (data.actual.block === null) data.actual.block = '';
    if (data.registration.flat === null) data.registration.flat = '';
    if (data.registration.block === null) data.registration.block = '';
    return dispatch('API', {
      method: 'post',
      body: data,
      url: INITIAL_ADDRESS,
      preloader: true,
    }, { root: true });
  },
  async SEND_ADDRESSES_PUT({
    dispatch,
  }, data) {
    try {
      return await dispatch('API', {
        method: 'put',
        body: data,
        url: INITIAL_ADDRESS,
        preloader: true,
      }, { root: true });
    } catch (rej) {
      return rej.response.data;
    }
  },
  async SEND_ADDITIONAL_INFO({
    dispatch,
  }, data) {
    return dispatch('API', {
      method: 'post',
      body: data,
      url: INITIAL_ADDITIONAL_INFO,
      preloader: true,
    }, { root: true });
  },
  async SEND_ADDITIONAL_INFO_PUT({
    dispatch,
  }, data) {
    try {
      return await dispatch('API', {
        method: 'put',
        body: data,
        url: INITIAL_ADDITIONAL_INFO,
        preloader: true,
      }, { root: true });
    } catch (rej) {
      return rej.response.data;
    }
  },
  async SEND_ESIA_ADDITIONAL_INFO({
    dispatch,
  }, data) {
    data = {
      ...data,
      calculator: {
        matched_ls_id: state.matched_ls_id,
        summ_requested: state.summ_requested,
        period_requested: state.period_requested,
      },
    };

    return dispatch('API', {
      method: 'post',
      body: data,
      url: INITIAL_LOAN_ESIA_ADDITIONAL_INFO,
      preloader: true,
    }, { root: true });
  },
  async SEND_ESIA_ADDITIONAL_INFO_PUT({
    dispatch,
  }, data) {
    try {
      data = {
        ...data,
        calculator: {
          matched_ls_id: state.matched_ls_id,
          summ_requested: state.summ_requested,
          period_requested: state.period_requested,
        },
      };

      return await dispatch('API', {
        method: 'put',
        body: data,
        url: INITIAL_LOAN_ESIA_ADDITIONAL_INFO,
        preloader: true,
      }, { root: true });
    } catch (rej) {
      return rej.response.data;
    }
  },
  async SEND_AGREE({
    dispatch,
  }, data) {
    return dispatch('API', {
      method: 'post',
      body: data,
      url: INITIAL_LOAN_FINALIZE_AGREE,
      preloader: true,
    }, { root: true });
  },
  async SEND_CODE({
    dispatch,
  }, data) {
    try {
      return await dispatch('API', {
        method: 'post',
        body: data,
        url: INITIAL_LOAN_FINALIZE_CODE,
        preloader: true,
      }, { root: true });
    } catch (rej) {
      return rej.response.data;
    }
  },
  async SEND_PASSPORT_PHOTO({
    dispatch,
  }, data) {
    return dispatch('API', {
      method: 'post',
      body: data,
      url: INITIAL_LOAN_PHOTO,
      preloader: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }, { root: true });
  },
  async SEND_PASSPORT_PHOTO_PUT({
    dispatch,
  }, data) {
    return dispatch('API', {
      method: 'put',
      body: data,
      url: INITIAL_LOAN_PHOTO,
      preloader: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }, { root: true });
  },
  async SEND_PASSWORD({
    dispatch,
  }, data) {
    const response = await dispatch('API', {
      method: 'post',
      body: data,
      url: INITIAL_LOAN_FINALIZE_PASSWORD,
      preloader: true,
    }, { root: true });

    Vue.prototype.$http.defaults.headers.common.Authorization = `token ${response.data.data.token}`;
    if (response.status === 200) {
      localStorage.removeItem('session_token');
      localStorage.setItem('token', response.data.data.token);
    }
    return response;
  },
  async GET_TOKEN({
    dispatch,
  }, payload) {
    try {
      const session_token = localStorage.getItem('session_token');

      if (!session_token) {
        await dispatch('GET_NEW_TOKEN', payload);
      } else {
        Vue.prototype.$http.defaults.headers.common.Authorization = `token ${session_token}`;
      }

      let { data: { data } } = await dispatch('API', {
        method: 'get',
        body: payload,
        url: INITIAL,
      }, { root: true });

      if (data?.esia) {
        dispatch('SET_LOAN_FIELD', { key: 'isEsiaActive', value: data?.esia });
      }

      if (!data?.recent_step) {
        data = await dispatch('GET_NEW_TOKEN', payload);
      } else {
        dispatch('SET_LOAN_FIELD', { key: 'personal', value: data?.personal });
        dispatch('SET_LOAN_FIELD', { key: 'uuid', value: data?.uuid });
      }

      return data;
    } catch (rej) {
      return rej.response.data;
    }
  },
  async GET_NEW_TOKEN({
    dispatch,
  }, payload) {
    const { data: { data } } = await dispatch('API', {
      method: 'post',
      body: payload,
      url: INITIAL,
    }, { root: true });
    Vue.prototype.$http.defaults.headers.common.Authorization = `token ${data?.session_token}`;
    localStorage.setItem('session_token', data?.session_token);

    return data;
  },
  async PUT_INITIAL_PARAMS({
    dispatch,
  }, payload) {
    return dispatch('API', {
      method: 'put',
      body: payload,
      url: INITIAL,
    }, { root: true });
  },
  async PUT_NEW_CALC_PARAMS({
    dispatch, state,
  }) {
    if (state.calcChanged) {
      const payload = {
        ...state.personal,
        matched_ls_id: state.matched_ls_id,
        summ_requested: state.summ_requested,
        period_requested: state.period_requested,
        update_calc: true,
      };

      dispatch('API', {
        method: 'put',
        body: payload,
        url: INITIAL_PERSONAL_INFO,
      }, { root: true });
    }
  },
  async GET_FULLINFO({
    dispatch,
  }) {
    const { data: { data } } = await dispatch('API', {
      method: 'get',
      url: INITIAL,
    }, { root: true });
    return data;
  },
  async GET_PERSONAL_DATA({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: INITIAL_PERSONAL_INFO,
    }, { root: true });
  },
  async GET_PASSPORT_DATA({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: INITIAL_PASSPORT,
    }, { root: true });
  },
  async GET_PASSPORT_PHOTO({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: INITIAL_LOAN_PHOTO,
    }, { root: true });
  },
  async GET_ADDRESS_DATA({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: INITIAL_ADDRESS,
    }, { root: true });
  },
  async GET_ADDITIONAL_DATA({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: INITIAL_ADDITIONAL_INFO,
    }, { root: true });
  },
  async GET_ESIA_ADDITIONAL_INFO({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: INITIAL_LOAN_ESIA_ADDITIONAL_INFO,
    }, { root: true });
  },
  async GET_LOAN_SUM({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: INITIAL_LOAN_SUM,
    }, { root: true });
  },
  async GET_LOAN_PERIOD({
    dispatch,
  }, sum) {
    return dispatch('API', {
      method: 'get',
      url: `${INITIAL_LOAN_PERIOD}${sum}`,
    }, { root: true });
  },
  async GET_LOAN_OPTIONS({
    dispatch,
  }, { sum, period }) {
    return dispatch('API', {
      method: 'get',
      url: replaceString(INITIAL_LOAN_OPTIONS, { sum, period }),
    }, { root: true });
  },
  async RESEND_SMS({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'post',
      url: INITIAL_RESEND_SMS,
    }, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
