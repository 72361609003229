import converters from '../js/converters';

export default {
  methods: {
    monthNameConverter(date) {
      return converters.getDayAndMonthName(date);
    },
    dateLabelConverter(date, dictionaryArray = ['день', 'дня', 'дней']) {
      return converters.declOfNum(Math.abs(date), dictionaryArray);
    },
    convertDateForServer(date) {
      return converters.convertDateForServer(date);
    },
    convertDateForClient(date) {
      return converters.convertDateForClient(date);
    },
    convertPhoneForServer(phone) {
      return converters.convertPhoneForServer(phone);
    },
    convertPhoneForClient(phone) {
      return converters.convertPhoneForClient(phone);
    },
    convertNumberToCopybookString(_number, cents) {
      /* 100 -> сто рублей, if(cents) -> 100 -> 100 рублей 0 копеек */
      return converters.convertNumberToCopybookString(_number, cents);
    },
  },
};
