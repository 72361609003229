const messages = {
  userAgreement: 'Подтвердите обработку персональных данных.',
  numeric: 'Требуется ввести цифры.',
  ru: 'Допустимы только символы кириллицы.',
  vinNumber: 'Некорректный VIN номер.',
  ptsSeria: 'Некорректный номер ПТС.',
  snils: 'Некорректный СНИЛС.',
  email: 'Некорректный E-mail.',
  isMaturity: 'Некорректная дата.',
  currentYear: 'Некорректная дата выдачи.',
  floatSumm: 'Введена некорректная сумма платежа.',
  bigPayment: 'Максимальная сумма оплаты - {value}',
  paymentEnabled: 'Подождите, предыдущий платеж в процессе обработки',
  minPrice: 'Сумма для оплаты должна быть больше 0.',
  correctPassportDateIssue: 'Некорректная дата выдачи.',
  correctDateFormat: 'Укажите дату в формате ДД.ММ.ГГГГ',
  mainPhoneNumber: 'Дополнительный номер не может совпадать с номером, на который вы зарегистрировались',
  sameAsPassword: 'Пароли не совпадают. Убедитесь, что Вы вводите правильный пароль и попробуйте ещё раз.',
  phone: 'Некорректный номер телефона',
  uniqPhones: 'Номера телефонов не должны совпадать',
  login: 'Логин или пароль не совпадают.',
  validateInn: 'Некорректный ИНН',
  chooseOne: 'Необходимо выбрать ответ',
  invalid: 'Поле заполнено неверно.',
  invalidField: 'Поле {attribute} заполнено неверно.',
  required: 'Поле не заполнено.',
  requiredNamed: 'Поле {attribute} не заполнено.',
  minLength: 'Минимальное количество символов - {min}',
  maxLength: 'Максимальное количество символов - {max}',
  birthday: 'Регистрация доступна с 18 лет',
  password: 'Пароль должен состоять из букв и содержать не менее одной цифры',
};

const attributes = {
  'phone.value': 'Телефон',
  'password.value': 'Пароль',
  'sms.value': 'СМС-код',
  'birthday.value': 'Дата рождения',
  'passport.value': 'Паспортные данные',
  'contract_number.value': 'Номер договора',
};

const validationKeys = {
  'minLength.value': {
    validationKey: messages.minLength,
    params: [
      {
        vue: 'min', // Vuelidate param name
        ext: 'min', // Parameter inside the message
      },
    ],
  },
  'bigPayment.value': {
    validationKey: messages.bigPayment,
    params: [
      {
        vue: 'value', // Vuelidate param name
        ext: 'value', // Parameter inside the message
      },
    ],
  },
  'maxLength.value': {
    validationKey: messages.maxLength,
    params: [
      {
        vue: 'max', // Vuelidate param name
        ext: 'max', // Parameter inside the message
      },
    ],
  },
};

export {
  messages,
  validationKeys,
  attributes,
};
