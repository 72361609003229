import {
  CONTRAGENT_INFO,
  CREATE_CONTRAGENT,
  INSURANCE_SIGN_DOCUMENT,
} from '@/helpers/js/urls';

const state = {};
const getters = {};
const mutations = {};
const actions = {
  async GET_CONTRAGENT_INFO({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: CONTRAGENT_INFO,
    }, { root: true });
  },
  async CREATE_CONTRAGENT({
    dispatch,
  }, data) {
    return dispatch('API', {
      method: 'post',
      url: CREATE_CONTRAGENT,
      preloader: true,
      body: data,
    }, { root: true });
  },
  async GET_SMS_CODE({
    dispatch,
  }) {
    const response = await dispatch('API', {
      method: 'get',
      url: INSURANCE_SIGN_DOCUMENT,
    }, { root: true });
    if (!response) return;
    localStorage.setItem('insuranceTimer', new Date().getTime());
    return response;
  },
  async SEND_SMS_CODE({
    dispatch,
  }, data) {
    return dispatch('API', {
      method: 'post',
      url: INSURANCE_SIGN_DOCUMENT,
      body: data,
    }, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
