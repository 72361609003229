import Vue from 'vue';
import VueRouter from 'vue-router';

import { routingLazyLoad } from '@/helpers/functions';

import resetPasswordRoutes from './ResetPasswordRoutes';
import authRoutes from './AuthRoutes';
import registrationRoutes from './RegistrationRoutes';
import loanRequestRoutes from './LoanRequestRoutes';
import getLoanRouter from './GetLoanRoutes';
import myLoansRoutes from './MyLoansRoutes';
import profileRoutes from './ProfileRoutes';
import identificationRoutes from './IdentificationRoutes';
import changePhoneRoutes from './СhangePhoneRoutes';
import insuranceRoutes from './InsuranceRoutes';

import store from '../store';

Vue.use(VueRouter);

const appRoutes = [
  {
    path: '/404/',
    name: '404',
    component: routingLazyLoad('NotFound'),
  },
  {
    path: '/maintenance/',
    name: 'Maintenance',
    component: routingLazyLoad('ServerMaintenance'),
    meta: {
      hideSidebar: true,
    },
  },
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/cabinet',
    name: 'Cabinet',
    component: routingLazyLoad('Cabinet'),
  },
  {
    path: '/map/',
    component: routingLazyLoad('Cabinet'),
    children: [{
      path: '',
      component: routingLazyLoad('Map'),
      name: 'Map',
      meta: {
        title: 'Адреса отделений',
        accessWithoutLogin: true,
      },
    }],
  },
  {
    path: '/help/',
    component: routingLazyLoad('Cabinet'),
    children: [{
      path: '',
      name: 'Help',
      component: routingLazyLoad('Questions', 'Help'),
      meta: {
        title: 'Помощь',
        accessWithoutLogin: true,
      },
    }],
  },
  {
    path: '/feedback/',
    name: 'Feedback',
    component: routingLazyLoad('Feedback'),
    meta: {
      sidebar: true,
      title: 'Обратная связь',
      breadcrumb: 'Обратная связь',
      requiresAuth: true,
    },
  },
  {
    path: '/settings/',
    name: 'Settings',
    component: routingLazyLoad('Settings'),

    meta: {
      sidebar: true,
      title: 'Настройки',
      breadcrumb: 'Настройки',
      requiresAuth: true,
    },
  },
  {
    path: '/best2pay/accept/',
    name: 'Best2PayAccept',
    component: routingLazyLoad('PaymentSystemResponsePage', 'CardBinding'),
  },
  {
    path: '/best2pay/fail/',
    name: 'Best2PayFail',
    component: routingLazyLoad('PaymentSystemResponsePage', 'CardBinding'),
  },
  {
    path: '/mandarin_response',
    name: 'MandarinResponse',
    component: routingLazyLoad('PaymentSystemResponsePage', 'CardBinding'),
  },
  {
    path: '/qiwi_response',
    name: 'QiwiResponse',
    component: routingLazyLoad('PaymentSystemResponsePage', 'CardBinding'),
  },
  {
    path: '/esia_auth/',
    name: 'EsiaAuth',
    component: routingLazyLoad('EsiaAuth'),
  },
  {
    path: '/test-guard',
    name: 'TestGuard',
    component: routingLazyLoad('TestGuard'),
  },
];

const routes = [
  ...appRoutes,
  ...authRoutes,
  ...registrationRoutes,
  ...loanRequestRoutes,
  ...resetPasswordRoutes,
  ...changePhoneRoutes,
  ...identificationRoutes,
  ...getLoanRouter,
  ...profileRoutes,
  ...myLoansRoutes,
  ...insuranceRoutes,
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

const originalPush = router.push;

// hide error/warning NavigationDuplicated
// eslint-disable-next-line func-names
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch(err => {
    if (VueRouter.isNavigationFailure(err)) {
      return err;
    }

    return Promise.reject(err);
  });
};

router.beforeEach(async (to, from, next) => {
  store.commit('CLEAR_ERRORS');

  if (to.matched.some(record => record.meta.requiresAuth || record.meta.accessWithoutLogin)) {
    if (store.getters['Auth/isLoggedIn']) {
      await store.dispatch('COMMON_BORROWER_DATA');

      if (to.meta.canNotGoFromUrl && !from.name) {
        next({ name: 'LoansList' });
      } else {
        next();
      }

      return;
    }

    if (to.meta.accessWithoutLogin) {
      next();
      return;
    }

    if (!to.meta.canNotGoFromUrl) {
      localStorage.setItem('routeNameBeforeLoginRedirect', to.path);
    }

    next({ name: 'Login' });
  } else if (!from.name && to.meta.canNotGoFromUrl) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

router.afterEach(() => {
  const pageContainer = document.querySelector('.page-container');
  window.scrollTo(0, 0);
  if (pageContainer != null && pageContainer.classList.contains('active')) {
    pageContainer.classList.remove('active');
  }
});

export default router;
