import Vue from 'vue';

import {
  SERVER,
  LOGIN,
  USER_LOGOUT,
  LOGIN_CHECK,
  CHECK_CONTRACT_DATA,
  CONFIRM_CODE,
  CHANGE_USER_PHONE,
  CHECK_PRIMARY_BORROWER,
  FETCH_ESIA_AUTH_URL,
  ESIA_LOGIN,
} from '@/helpers/js/urls';
import router from '@/router/Router';

const state = {
  token: localStorage.getItem('token') || null,
  accessKey: localStorage.getItem('access_key') || null,
  smsErrorTimer: null,
};
const getters = {
  isLoggedIn: state => !!state.token,
};
const mutations = {
  SET_ERROR_TIMER(state, data) {
    state.smsErrorTimer = data;
  },
  AUTH_SUCCESS(state, token) {
    Vue.prototype.$http.defaults.headers.common.Authorization = `token ${token}`;
    localStorage.setItem('token', token);
    state.token = token;
  },
  ACCESS_KEY(state, key) {
    if (key) {
      localStorage.setItem('access_key', key);
    } else {
      localStorage.removeItem('access_key');
    }

    state.accessKey = key;
  },
  LOGOUT(state) {
    state.token = null;
  },
};
const actions = {
  // Блок запросов по регистрации
  AUTH_SUCCESS({
    commit,
  }, data) {
    localStorage.setItem('token', data);
    commit('AUTH_SUCCESS', data);
  },
  async SUCCESS_ENTRY({ dispatch, commit, rootState }) {
    commit('ACCESS_KEY', null);

    await dispatch('COMMON_BORROWER_DATA', null, { root: true });

    const routeNameBeforeLoginRedirect = localStorage.getItem('routeNameBeforeLoginRedirect');

    if (routeNameBeforeLoginRedirect) {
      await router.push(routeNameBeforeLoginRedirect);
    } else if (rootState?.borrowerData?.active_loan) {
      await router.push({
        name: 'LoansList',
      });
    } else if (rootState?.borrowerData?.identification_status === 0) {
      await router.push({
        name: 'IdentificationResult',
      });
    } else {
      await router.push({
        name: 'LoanProducts',
      });
    }

    dispatch('GET_DIRECTORY_DATA', null, { root: true });
  },
  async CHECK_PRIMARY_BORROWER({
    dispatch,
  }, { data, showEmptyErrors = false }) {
    try {
      return await dispatch('API', {
        method: 'post',
        body: data,
        url: CHECK_PRIMARY_BORROWER,
        preloader: true,
        showEmptyErrors,
      }, { root: true });
    } catch (rej) {
      return rej.response.data;
    }
  },
  // Блок запросов по авторизации
  async LOGIN_CHECK({
    commit,
    dispatch,
    rootState,
  }, data) {
    const response = await dispatch('API', {
      method: 'post',
      url: LOGIN_CHECK,
      body: data,
    }, { root: true });
    if (rootState.errors.length > 0) {
      for (const error in rootState.errors) {
        if (rootState.errors[error].code === 'sms_passed') {
          router.push({
            name: 'LoansList',
          });
        } else if (rootState.errors[error].code === 'expired_sms_code') {
          router.push({
            name: 'Login',
            params: {
              expiredModal: true,
            },
          });
        }
      }
    } else {
      const { token } = response.data.data;

      // if (!localStorage.getItem('isVoteInGooglePool1305')) {
      //   Vue.prototype.$modal.show('google-form-pool');
      // }

      if (localStorage.getItem('loginTimer')) {
        localStorage.removeItem('loginTimer');
      }

      localStorage.setItem('token', token);
      commit('AUTH_SUCCESS', token);
      dispatch('SUCCESS_ENTRY');
    }
  },
  async LOGIN({
    commit,
    dispatch,
  }, data) {
    try {
      const response = await dispatch('API', {
        method: 'post',
        url: LOGIN,
        body: data,
        preloader: true,
      }, { root: true });
      if (!response) return;
      const key = response.data.data.access_key;
      commit('ACCESS_KEY', key);
      localStorage.setItem('loginTimer', new Date().getTime());
      localStorage.removeItem('phone');
      router.push({
        name: 'AuthSMS',
      });
    } catch (rej) {
      if (rej?.response?.status === 429) {
        commit('SET_ERROR_TIMER', rej?.response?.data?.data?.message[0]);
        commit('CLEAR_ERRORS', {}, { root: true });
      }
    }
  },
  async CHECK_CONTRACT_DATA({
    commit,
    dispatch,
  }, data) {
    const response = await dispatch('API', {
      method: 'post',
      url: CHECK_CONTRACT_DATA,
      body: data,
    }, { root: true });

    if (response) {
      const key = response.data.data.access_key;
      commit('ACCESS_KEY', key);
    }

    return response;
  },
  async CHANGE_USER_PHONE({
    dispatch,
  }, data) {
    const response = await dispatch('API', {
      method: 'post',
      url: CHANGE_USER_PHONE,
      body: data,
    }, { root: true });
    localStorage.setItem('change_phoneTimer', new Date().getTime());
    return response;
  },
  async CONFIRM_CODE({
    dispatch,
  }, data) {
    const response = await dispatch('API', {
      method: 'post',
      url: CONFIRM_CODE,
      body: data,
      preloader: true,
    }, { root: true });

    if (response) {
      localStorage.removeItem('change_phoneTimer');
    }

    return response;
  },
  ENTRY_ERROR({
    commit,
    state,
  }) {
    if (!state.token) return;
    delete Vue.prototype.$http.defaults.headers.common.Authorization;
    localStorage.removeItem('token');

    const currentRoute = router.history && router.history.current && router.history.current.to;

    commit('LOGOUT');

    if (currentRoute && !currentRoute.to.meta.canNotGoFromUrl) {
      localStorage.setItem('routeNameBeforeLoginRedirect', currentRoute);
    }

    router.push({
      name: 'Login',
      params: {
        modal: true,
      },
    });
  },
  async LOGOUT({
    commit,
  }) {
    await Vue.prototype.$http.post(SERVER + USER_LOGOUT);

    if (localStorage.getItem('token')) {
      delete Vue.prototype.$http.defaults.headers.common.Authorization;
      localStorage.removeItem('token');
    }

    commit('LOGOUT');
    commit('BORROWER_DATA', null, { root: true });
    localStorage.removeItem('routeNameBeforeLoginRedirect');
    router.push({
      name: 'Login',
    });
  },
  async FETCH_ESIA_AUTH_URL({
    dispatch,
  }, payload) {
    return dispatch('API', {
      method: 'post',
      body: payload,
      url: FETCH_ESIA_AUTH_URL,
    }, { root: true });
  },
  async ESIA_LOGIN({
    commit,
    dispatch,
    rootState,
  }, payload) {
    try {
      const session_token = localStorage.getItem('session_token');
      const response = await dispatch('API', {
        method: 'post',
        url: ESIA_LOGIN,
        headers: {
          Authorization: `token ${session_token}`,
        },
        body: payload,
      }, { root: true });

      if (rootState.errors.length > 0) {
        return;
      }

      const data = response.data;

      if (data?.data?.token) {
        localStorage.setItem('token', data?.data?.token);
        commit('AUTH_SUCCESS', data?.data?.token);
        dispatch('SUCCESS_ENTRY');
        return;
      }

      if (data?.success) {
        await router.push({
          name: 'LoanRequestAdditionalInfo',
        });
      }
    } catch (e) {
      if (e.response?.data?.data?.server_error) {
        await router.push({
          name: 'LoanRequestPersonal',
          params: {
            esiaErrorModal: true,
            esiaModalErrorMessage: e.response?.data?.data?.server_error.message[0],
          },
        });
        return;
      }

      if (e.response?.data?.data?.error) {
        await router.push({
          name: 'Login',
          params: {
            esiaErrorModal: true,
            esiaModalErrorMessage: e.response?.data?.data?.error,
          },
        });
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
