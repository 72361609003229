import { routingLazyLoad } from '@/helpers/functions';

const identificationRoutes = [
  {
    path: '/identification/',
    component: routingLazyLoad('Cabinet'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'IdentificationForm',
        component: routingLazyLoad('IdentificationForm', 'Identification'),
        meta: {
          requiresAuth: true,
          sidebar: 'Идентификация',
          breadcrumb: 'Идентификация',
        },
      }, {
        path: 'result',
        name: 'IdentificationResult',
        component: routingLazyLoad('IdentificationResult', 'Identification'),

        meta: {
          sidebar: true,
          breadcrumb: 'Результаты идентификации',
          requiresAuth: true,
        },
      },
    ],
  },
];

export default identificationRoutes;
