<template>
  <header
    ref="header"
    :class="['header secondary', { backshadow: headerShadowIsVisible }]"
  >
    <v-container>
      <v-row
        justify="space-between"
        align="center"
      >
        <v-col
          class="py-0"
          cols="auto"
        >
          <v-row align="center">
            <v-col
              v-if="($route.meta.sidebar || $route.meta.accessWithoutLogin) && isLoggedIn"
              class="d-flex d-lg-none pa-lg-0"
              cols="auto"
            >
              <div
                ref="mobileMenu"
                class="mobile-menu d-flex align-center"
                @click="menuToggle()"
              >
                <div class="mobile-menu__trigger pr-3">
                  <span class="primary" />
                  <span class="primary" />
                  <span class="primary" />
                </div>
                <span class="white--text">Меню</span>
              </div>
              <div class="mobile-sidebar">
                <Sidebar />
              </div>
            </v-col>
            <v-col class="py-0">
              <logo
                link-to-root
              >
                <template #image>
                  <img src="@/assets/images/Logo-COVID.svg">
                </template>
              </logo>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="d-none d-lg-flex py-0"
          cols="auto"
        >
          <contacts :is-header-contacts="true" />
        </v-col>
      </v-row>
    </v-container>
    <div class="banner">
      <p>
        В связи с техническими неполадками выдача займов через личный кабинет временно недоступна.<br>
        Для получения займа приглашаем Вас в любой офис под знаком обслуживания "Деньга".
      </p>
    </div>
  </header>
</template>
<script>
import { mapGetters } from 'vuex';

import Logo from '@/components/Logo.vue';

import Contacts from '@/components/Contacts.vue';
import Sidebar from '../Sidebar.vue';

export default {
  components: {
    Sidebar,
    Logo,
    Contacts,
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'Auth/isLoggedIn',
    }),
    headerShadowIsVisible() {
      return this.$route && this.$route.matched[0] && this.$route.matched[0].path !== '/lp';
    },
  },
  methods: {
    menuToggle() {
      const pageContainer = document.querySelector('.page-container');

      if (pageContainer.classList.contains('active')) {
        pageContainer.classList.remove('active');
      } else {
        pageContainer.classList.add('active');
      }
    },
    hideHeaderOnScroll() {
      const element = this.$refs.header;
      const displayWidth = document.body.clientWidth;
      const sidebar = document.querySelector('.sidebar');
      if (!element || displayWidth > 1024) return;

      let elHeight = 0;
      let elTop = 0;
      let sTop = 0;
      let dHeight = 0;
      let wHeight = 0;
      let wScrollCurrent = 0;
      let wScrollBefore = 0;
      let wScrollDiff = 0;

      window.addEventListener('scroll', () => {
        elHeight = element.offsetHeight;
        dHeight = document.body.offsetHeight;
        wHeight = window.innerHeight;
        wScrollCurrent = window.pageYOffset;
        wScrollDiff = wScrollBefore - wScrollCurrent;
        elTop = parseInt(window.getComputedStyle(element).getPropertyValue('top'), 10) + wScrollDiff;
        sTop = parseInt(window.getComputedStyle(sidebar).getPropertyValue('top'), 10) + wScrollDiff;
        // ...
        if (wScrollCurrent <= 0) {
          element.style.top = '0';
          sidebar.style.top = '71px';
        } else if (wScrollDiff > 0) {
          element.style.top = `${elTop > 0 ? 0 : elTop}px`;
          sidebar.style.top = `${sTop > 71 ? 71 : sTop}px`;
        } else if (wScrollDiff < 0) {
          if (wScrollCurrent + wHeight >= dHeight - elHeight) {
            elTop = wScrollCurrent + wHeight - dHeight;
            sTop = wScrollCurrent + wHeight - dHeight + 71;
            element.style.top = `${elTop < 0 ? elTop : 0}px`;
            sidebar.style.top = `${sTop < 71 ? sTop : 71}px`;
          } else {
            element.style.top = `${Math.abs(elTop) > elHeight ? -elHeight : elTop}px`;// scrolled to the very bottom; element slides in
            sidebar.style.top = `${Math.abs(elTop) > elHeight ? 0 : sTop}px`;
          }
        }

        wScrollBefore = wScrollCurrent;
      });
    },
  },

};

</script>
<style lang="scss">
    .header {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        background: #f6f6f8;
        z-index: 1100;

      .banner {
        display: none;
        background-color: #f47d06;
        padding: 10px;
        text-align: center;
        p {
          font-size: 14px;
          font-weight: 600;
          color: #ffffff;
          margin: 0;
        }
      }

      &.backshadow {
        &:after {
          content: '';
          position: absolute;
          //top: 176.33px;
          bottom: -20px;
          right: 0;
          left: 0;
          z-index: 10;
          height: 20px;
          background: linear-gradient(#f6f6f8 0%, rgba(255, 255, 255, 0) 100%);
        }
      }

        .mobile-menu {
            z-index: 300;
            -webkit-user-select: none;
            user-select: none;

            &__trigger {
                span {
                    display: flex;
                    width: 12px;
                    height: 2px;
                    margin-bottom: 2px;
                    position: relative;
                    background: $color-dark-blue;
                    border-radius: 3px;
                    z-index: 1;
                    transform-origin: 5px 0;
                    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
                    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
                    opacity 0.55s ease;

                    &:first-child {
                        transform-origin: 0% 0%;
                    }

                    &:nth-last-child(2) {
                        transform-origin: 0% 100%;
                    }
                }
            }
        }
    }
</style>
