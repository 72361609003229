import { routingLazyLoad } from '@/helpers/functions';

const insuranceRoutes = [
  {
    path: '/insurance/',
    component: routingLazyLoad('Cabinet'),
    meta: {
      sidebar: true,
      requiresAuth: true,
      title: 'Страхование',
    },
    children: [
      {
        path: '',
        component: routingLazyLoad('InsuranceProducts', 'Insurance'),
        name: 'InsuranceProducts',
        meta: {
          sidebar: true,
          title: 'Страхование',
        },
      },
      {
        path: 'payment',
        component: routingLazyLoad('InsurancePayment', 'Insurance'),

        name: 'InsurancePayment',
        meta: {
          sidebar: true,
          title: 'Оплата страхования онлайн',
        },
      },
      {
        path: 'confirmation',
        component: routingLazyLoad('InsuranceCVC', 'Insurance'),

        name: 'InsuranceConfirmation',
        meta: {
          canNotGoFromUrl: true,
          sidebar: true,
          title: 'Оплата страхования онлайн',
        },
      },
      {
        path: ':slug',
        name: 'InsuranceForm',
        component: routingLazyLoad('InsuranceForm', 'Insurance'),
        meta: {
          sidebar: true,
          title: false,
        },
      },
    ],
  },
];

export default insuranceRoutes;
