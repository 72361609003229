<template>
  <default-modal
    v-bind="$attrs"
    :max-width="600"
    adaptive
    height="auto"
  >
    <div class="d-flex flex-sm-row flex-column align-center justify-center">
      <div
        v-if="!noImage"
        class="mr-4"
      >
        <img
          v-if="success"
          src="@/assets/images/reg-success.svg"
        >
        <img
          v-if="error"
          src="@/assets/images/reg-fail.svg"
        >
      </div>
      <div class="text-h5 font-weight-medium mt-3 mt-sm-0">
        <slot />
      </div>
    </div>
  </default-modal>
</template>

<script>
import DefaultModal from './DefaultModal.vue';

export default {
  name: 'ResultBox',
  components: {
    DefaultModal,
  },
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    noImage: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
    .result-box {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        position: relative;

        &__icon {
            margin-right: 20px;
        }

        &__text {
            font-size: 18px;
            @include ff_semibold;
        }

        @media screen and (max-width: 420px) {
            display: block;

            &__icon {
                text-align: center;
                width: 70px;
                margin: 0 auto 10px;
            }
        }
    }
</style>
