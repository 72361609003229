import {
  ADDITIONAL_LOAN_INFO,
  CANCEL_LOAN,
  CONFIRM_SMS,
  CREATE_LOAN,
  EDIT_LOAN,
  LOAN_CALLBACK,
  LOAN_DOCS,
  LOAN_PRODUCTS_LIST,
  PAYMENT_OPTIONS,
  PERIOD_LIST,
  SAVE_BORROWER,
  SAVE_LOAN,
  SIGNING_DOCUMENT,
  SUMMS_LIST,
  GET_PERSONAL_OFFERS,
  COMMON_SUMMS_LIST,
} from '@/helpers/js/urls';
import router from '@/router/Router';

function convertStepForRoute(type) {
  // Словарь шагов создавался для того что бы для разных роутов можно было
  // задать свое собственное имя, для того что бы ссылка в меню и в адресной строке была корректной
  return [`${type}:personal_info`, `${type}:passport_photos`, `${type}:personal_address`, `${type}:job_income`, `${type}:bank_card`, `${type}:full_info_profile`];
}

const state = {
  loanSteps: null,
  insuranceCost: null,
  smsInformingCost: null,
  profileSteps: null,
  showProfileSteps: null,
  additionalSteps: null,
  additionalInfo: null,
  loanSum: null,
  securityComment: null,
  showAdditionalServices: null,
  insurance_company_name: null,
};
const getters = {
  ADDITIONAL_STEPS: state => state.additionalSteps,
  SHOW_PROFILE_STEPS: state => state.showProfileSteps,
  // eslint-disable-next-line no-shadow
  loanOnRework: (state, getters, rootState) => rootState.borrowerData.edit_profile && rootState.borrowerData.active_loan,
  // eslint-disable-next-line no-shadow
  loanSumIsBig: (state, getters, rootState) => +rootState.borrowerData.loan_summ >= 40000,
};
const mutations = {
  LOAN_SERVICES: (state, payload) => {
    state.showAdditionalServices = payload;
  },
  LOAN_SUMM: (state, data) => {
    state.loanSum = data;
  },
  ADDITIONAL_SERVICES: (state, data) => {
    state.insuranceCost = data.insurance_summ;
    state.smsInformingCost = data.sms_informing_price;
    state.insurance_company_name = data.insurance_company_name;
  },
  SECURITY_COMMENT: (state, data) => {
    state.securityComment = data;
  },
  ALL_STEPS: (state, type) => {
    state.loanSteps = [];
    const unfilterLoanSteps = [];

    if (state.additionalSteps != null) {
      for (const step in state.additionalSteps) {
        unfilterLoanSteps.push(state.additionalSteps[step]);
      }
    }

    for (const step in state.profileSteps) {
      unfilterLoanSteps.push(state.profileSteps[step]);
    }

    state.loanSteps = convertStepForRoute(unfilterLoanSteps, type, true);
  },
  FILTER_STEPS: (state, data) => {
    state.loanSteps = convertStepForRoute(data.type);
  },
};
const actions = {
  async ADDITIONAL_SERVICES({
    dispatch,
  }, data) {
    await dispatch('API', {
      method: 'post',
      url: ADDITIONAL_LOAN_INFO,
      body: data,
    }, { root: true });
  },
  async GET_LOAN_DOCS({
    dispatch,
  }, data) {
    return dispatch('API', {
      method: 'get',
      url: LOAN_DOCS,
      body: data,
      preloader: true,
    }, { root: true });
  },
  async LOAN_CALLBACK({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'post',
      url: LOAN_CALLBACK,
    }, { root: true });
  },
  async PRODUCT_INFO({
    dispatch,
  }, data) {
    return dispatch('API', {
      method: 'get',
      url: `${LOAN_PRODUCTS_LIST + data}/`,
    }, { root: true });
  },
  async SMS_SIGNING_DOCUMENT({
    dispatch,
  }) {
    await dispatch('API', {
      method: 'get',
      url: SIGNING_DOCUMENT,
      preloader: true,
    }, { root: true });
  },
  async SIGNING_DOCUMENT({
    dispatch,
    rootState,
  }, data) {
    await dispatch('API', {
      method: 'post',
      url: SIGNING_DOCUMENT,
      preloader: true,
      body: data,
    }, { root: true });
    if (rootState.errors.length > 0) return;

    if (localStorage.getItem('loan_applicationTimer')) {
      localStorage.removeItem('loan_applicationTimer');
    }
  },
  async SIGNING_DOCUMENT_SMS({
    dispatch,
  }) {
    await dispatch('API', {
      method: 'get',
      url: SIGNING_DOCUMENT,
    }, { root: true });
  },
  async GET_PERSONAL_OFFERS({
    dispatch,
  }) {
    try {
      return await dispatch('API', {
        method: 'get',
        url: GET_PERSONAL_OFFERS,
      }, { root: true });
    } catch (rej) {
      return rej.response.data;
    }
  },
  async PRODUCTS({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: LOAN_PRODUCTS_LIST,
    }, { root: true });
  },
  async PAYMENT_OPTIONS({
    commit,
    dispatch,
  }, data) {
    const response = await dispatch('API', {
      method: 'post',
      url: PAYMENT_OPTIONS,
      body: data,
    }, { root: true });
    commit('ADDITIONAL_SERVICES', response.data.data);
    return response;
  },
  async SUMMS_LIST({
    dispatch,
  }, data) {
    return dispatch('API', {
      method: 'post',
      url: SUMMS_LIST,
      body: data,
    }, { root: true });
  },
  async COMMON_SUMMS_LIST({
    dispatch,
  }) {
    const { data: { data } } = await dispatch('API', {
      method: 'get',
      url: COMMON_SUMMS_LIST,
    }, { root: true });

    return data;
  },
  async ADDTITIONAL_LOAN_INFO({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: ADDITIONAL_LOAN_INFO,
    }, { root: true });
  },
  async MOUNTH_LIST({
    dispatch,
  }, data) {
    return dispatch('API', {
      method: 'post',
      url: PERIOD_LIST,
      body: data,
    }, { root: true });
  },
  async START({
    commit,
    dispatch,
  }, data) {
    const response = await dispatch('API', {
      method: 'post',
      url: SAVE_LOAN,
      body: data,
      preloader: true,
    }, { root: true });

    if (!response) return;
    commit('LOAN_SERVICES', response.data.data.steps.show_additional_loan_services);
    commit('FILTER_STEPS', {
      steps: response.data.data.steps,
      type: 'create',
    });
  },
  async EDIT_LOAN({
    commit,
    dispatch,
    rootState,
  }) {
    const response = await dispatch('API', {
      method: 'post',
      url: EDIT_LOAN,
    }, { root: true });

    if (rootState.errors.length > 0) {
      router.push({
        name: 'LoansList',
      });
    } else {
      commit('FILTER_STEPS', {
        steps: response.data.data.steps,
        type: 'edit',
      });

      commit('SECURITY_COMMENT', response.data.data.comment);
    }
  },
  async CREATE_LOAN({
    dispatch,
    rootState,
  }, data) {
    await dispatch('API', {
      method: 'post',
      url: CREATE_LOAN,
      body: data,
      preloader: true,
    }, { root: true });
    if (rootState.errors.length > 0) return;
    if (localStorage.getItem('borrower_profile_applicationTimer')) {
      localStorage.removeItem('borrower_profile_applicationTimer');
    }

    router.push({
      name: 'SuccessCreateLoan',
    });
  },
  async SAVE_BORROWER({
    dispatch,
    rootState,
  }, data) {
    await dispatch('API', {
      method: 'post',
      url: SAVE_BORROWER,
      body: data,
    }, { root: true });
    if (rootState.errors.length > 0) return;
    if (localStorage.getItem('borrower_profile_applicationTimer')) {
      localStorage.removeItem('borrower_profile_applicationTimer');
    }
  },
  async CANCEL_LOAN({
    dispatch,
  }) {
    await dispatch('API', {
      method: 'post',
      url: CANCEL_LOAN,
    }, { root: true });
    if (localStorage.getItem('loan_applicationTimer')) {
      localStorage.removeItem('loan_applicationTimer');
    }
    await dispatch('COMMON_BORROWER_DATA', null, { root: true });
  },
  async CONFIRM_SMS({
    dispatch,
  }, data) {
    await dispatch('API', {
      method: 'post',
      url: CONFIRM_SMS,
      body: data,
      preloader: true,
    }, { root: true });

    localStorage.setItem('borrower_profile_applicationTimer', new Date().getTime());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
