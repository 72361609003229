export default {
  ERROR_BIND: 'Ошибка привязки',
  HAVE_NO_CARD: 'Нет привязанной карты',
  IN_PROCESS: 'В процессе',
  IN_CHECK_PROCESS: 'В процессе проверки',
  UNBIND_CARD: 'Карта отвязана',
  BIND_CARD: 'Карта привязана',
  CARD_LOCK: 'Карта заблокирована банком',
  DENIED: 'Отказано',
};
