import { routingLazyLoad } from '@/helpers/functions';

const registrationRoutes = [
  {
    path: '/registration/',
    name: 'Registration',
    component: routingLazyLoad('SignIn'),
    meta: {
      title: 'Регистрация в личном кабинете',
    },
  },
  {
    path: '/registration/check/',
    name: 'SendSMS',
    component: routingLazyLoad('SmsPage'),
    props: {
      dispatchType: 'Registration/SMS',
      timerType: 'registration',
      startOverPage: 'Registration',
    },
    meta: {
      isConfirmTitle: true,
      smallTitle: true,
    },
  },
  {
    path: '/registration/check-passport',
    name: 'CheckPassport',
    component: routingLazyLoad('CheckPassport'),
    meta: {
      title: 'Похоже Вы уже брали у нас заём! <br> Нам нужно проверить, что это именно Вы',
      smallTitle: true,
      canNotGoFromUrl: true,
    },
  },
  {
    path: '/registration/create-password',
    name: 'CreatePassword',
    component: routingLazyLoad('Password'),
    props: {
      dispatchType: 'Registration/FINAL',
      type: 'registration',
    },
    meta: {
      title: 'Укажите пароль от Вашего личного кабинета',
      smallTitle: true,
      canNotGoFromUrl: true,
    },
  },
];

export default registrationRoutes;
