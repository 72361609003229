import Vue from 'vue';

import VueMeta from 'vue-meta';
import vuetify from '@/plugins/vuetify';

import Vuelidate from 'vuelidate';
import VModal from 'vue-js-modal';
import axios from 'axios';
import VueTheMask from 'vue-the-mask';
import VueAnalytics from 'vue-analytics';
import VueYandexMetrika from 'vue-yandex-metrika';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faVk, faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import VuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor';
import {
  faTimes,
  faInfoCircle,
  faPlus,
  faMinus,
  faImages,
  faChevronRight,
  faCheck,
  faRubleSign,
  faSyncAlt,
  faEllipsisV,
  faInfo,
  faArrowRight,
  faExclamationCircle,
  faArrowLeft,
  faSearchPlus,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  faEnvelope, faFile, faTrashAlt, faImage,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { messages, attributes, validationKeys } from '@/helpers/i18n/validationMessages';
import Moment from 'moment';
import { initSentry } from '@/helpers/sentry';
import { createYmapsVue2 } from 'vue-yandex-maps';
import packageJson from '../package.json';
import consts from './helpers/consts';

import store from './store';
import router from './router/Router';
import App from './App.vue';
import BaseForm from './components/base-components/BaseForm.vue';
import BaseSubmit from './components/base-components/BaseSubmit.vue';
import FormErrors from './components/FormErrors.vue';
import FormField from './components/FormField.vue';
import BaseButton from './components/base-components/BaseButton.vue';
import { SERVER } from './helpers/js/urls';

initSentry();

Moment.locale('ru');
Vue.prototype.$moment = Moment;

console.log('Версия приложения: ', packageJson.version);

library.add(faTimes,
  faImages,
  faPlus,
  faMinus,
  faChevronRight,
  faCheck,
  faRubleSign,
  faSyncAlt,
  faEllipsisV,
  faInfo,
  faArrowRight,
  faArrowLeft,
  faInfoCircle,
  faExclamationCircle,
  faTrashAlt,
  faSearchPlus,
  faImage,
  faCheckCircle, faVk, faTelegramPlane, faEnvelope, faFile);

const settings = {
  apikey: '417fa6fe-43c1-431a-8058-5678c0ed85fe',
};

Vue.use(createYmapsVue2(settings));

Vue.use(VueTheMask);

Vue.use(VueAnalytics, {
  id: 'UA-148869153-1',
  router,
});

Vue.use(VueYandexMetrika, {
  id: '55502683',
  router,
  env: process.env.VUE_APP_SERVER_URL === 'https://lk.denga.ru' ? 'production' : 'dev',
});

Vue.use(VueMeta);
Vue.use(Vuelidate);
Vue.use(VuelidateErrorExtractor, {
  template: FormField,
  attributes,
  messages,
  validationKeys,
});
Vue.use(VModal, { dynamic: true, injectModalsContainer: true });

Vue.component('FormWrapper', templates.FormWrapper);
Vue.component('BaseForm', BaseForm);
Vue.component('BaseSubmit', BaseSubmit);
Vue.component('FormErrors', FormErrors);
Vue.component('FormField', FormField);
Vue.component('Fai', FontAwesomeIcon);
Vue.component('BaseButton', BaseButton);

const instance = axios.create({
  baseURL: SERVER,
});

Vue.prototype.$http = instance;
Vue.prototype.$consts = consts;

const token = localStorage.getItem('token');

if (token) {
  Vue.prototype.$http.defaults.headers.common.Authorization = `token ${token}`;
}

router.onError(error => {
  if (/Loading.*chunk.*failed./i.test(error.message)) {
    store.commit('UPDATE_ERROR', true);
  }
});
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app');
