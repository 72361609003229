<template>
  <v-row align="center">
    <v-col class="py-0">
      <a
        class="d-flex logo"
        href="https://denga.ru/"
      >
        <slot name="image" />
      </a>
    </v-col>
    <v-col
      v-if="linkToRoot"
      class="py-0"
    >
      <router-link
        :class="$vuetify.breakpoint.xsOnly && 'caption'"
        :to="{name: !isLoggedIn ? 'Login' : 'LoansList' }"
        class="white--text font-weight-medium-plus text-no-wrap link link-hover-red"
      >
        Личный кабинет
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Logo',
  props: {
    linkToRoot: Boolean,
  },
  computed: {
    ...mapState({
      borrowerData: state => state.borrowerData,
    }),
    ...mapGetters({
      isLoggedIn: 'Auth/isLoggedIn',
    }),
  },
};
</script>

<style lang="scss">

    .logo {
        display: flex;
        align-items: center;
        @include ff_semibold;

        &__image {
            width: 82px;
        }

        &__label {
            margin-left: 23px;
            position: relative;
            font-size: 16px;

            a {
                text-decoration: none;
                color: $color-dark-blue;
            }

            &:after {
                content: '';
                position: absolute;
                left: -12px;
                top: -6px;
                height: 32px;
                width: 1px;
                background-color: #bdc2c8;
            }
        }
    }
</style>
