<template>
  <form
    action=""
    class="form"
    method="post"
    @submit.prevent="onSubmit"
  >
    <div
      class="form__wrapper"
      :class="{'form__wrapper--center': type === 'auth'}"
    >
      <div class="form__notifications">
        <template v-if="type === 'profile'">
          <BaseNotification
            v-if="!borrowerData.edit_profile"
            title="Редактирование анкеты недоступно"
            float-icon
          >
            <div v-html="editLoanError" />
          </BaseNotification>
          <BaseNotification
            v-if="$route.meta.type === 'edit' && securityComment"
            theme="info"
            title="Сообщение от СЭБ"
            float-icon
          >
            <div v-html="securityComment" />
          </BaseNotification>
          <BaseNotification
            v-else-if="isNewLoanBlocked && isGetLoanSteps"
            float-icon="float-icon"
          >
            <div>
              Вы временно не можете подавать заявки на заём. Воспользуйтесь услугами наших партнеров.
              <router-link :to="{ name: 'LoansList' }">
                Перейти
              </router-link>
            </div>
          </BaseNotification>
          <slot name="notifications" />
        </template>
      </div>
      <div
        v-if="!showErrorsBelow"
        class="form__errors"
      >
        <form-errors
          v-if="!$slots.hasOwnProperty('errors') && (hasServerErrors || alwaysShowError)"
          class="mb-4"
        />
        <slot name="errors" />
      </div>
      <div class="form__body">
        <slot name="body" />
      </div>
      <div
        class="form__footer"
        :class="{'form__wrapper--sticky': type === 'profile'}"
      >
        <div
          v-if="showErrorsBelow"
          class="form__errors"
        >
          <form-errors
            v-if="!$slots.hasOwnProperty('errors') && (hasServerErrors || alwaysShowError)"
            class="mb-4"
          />
          <slot name="errors" />
        </div>
        <slot name="footer" />
      </div>
    </div>
  </form>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import newLoanBlocked from '@/helpers/mixins/newLoanBlocked';

import BaseNotification from './BaseNotification.vue';

export default {
  name: 'BaseForm',
  components: {
    BaseNotification,
  },
  mixins: [newLoanBlocked],
  props: {
    type: {
      type: String,
      default: 'common',
    },
    validator: {
      type: Object,
      required: true,
    },
    /* Флаг необходим для того что бы форма могла отображать все ошибки */
    alwaysShowError: {
      type: Boolean,
      default: false,
    },
    showErrorsBelow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formValid: false,
      hasServerErrors: false,
    };
  },
  computed: {
    ...mapGetters({
      editLoanError: 'editLoanError',
    }),
    ...mapState('GetLoan', {
      securityComment: state => state.securityComment,
    }),
    ...mapState({
      borrowerData: state => state.borrowerData,
      errors: state => state.errors,
      requestStatus: state => state.requestStatus,
    }),
  },
  watch: {
    requestStatus() {
      /* Необходим для того что бы можно было корректно показывать ошибки если на странице две формы сразу */
      /* Из за того что все ошибки с сервера записываются в один массив и выводятся в каждой форме, то возникала ситуация, при нахождении
        * на странице 2 форм, и если на одной из форм запрос а сервер выдавал ошибку, то ошибка была на двух формах сразу.
        * Флаг alwaysShowError сделана для того что бы, если вдруг возникнет ситуация в которой понадобится отображать все ошибки что есть
        * в хранилище, то надо передать true */
      if (this.requestStatus === 'pending') {
        this.hasServerErrors = false;
      }

      if (this.requestStatus === 'finish' && this.formValid) {
        this.hasServerErrors = true;
        this.formValid = false;
      }
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit');

      if (!this.validator.$anyError) {
        this.formValid = true;
      }
    },
  },
};
</script>

<style lang="scss">
   .form {
       &__wrapper {
       &--center {
           max-width: 472px;
           width: 100%;
           margin: 0 auto 40px;

           .form__errors {
               display: flex;
               justify-content: center;
           }

           .form__body {
               max-width: 352px;
               width: 100%;
               margin: 0 auto 40px;
           }

         .form-submit {
           text-align: center;
         }
       }

       &--sticky {
         position: sticky;
         bottom: 100px;
         float: right;
         z-index: 7;
         max-width: 330px;

         @media screen and (max-width: 1100px) {
           bottom: 40px;
         }

         @media screen and (max-width: 768px) {
           position: relative;
           bottom: 0;
         }
       }
     }

     &__footer {
       @media screen and (max-width: 480px) {
         float: none;
       }
     }

     &__body {
       margin-bottom: 40px;

       @media screen and (max-width: 580px) {
         margin-bottom: 25px;
       }
     }

     .notification, .errors {
       margin-bottom: 20px;
     }

     &__header {
       margin-bottom: 20px;
     }
   }
</style>
