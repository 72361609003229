<template>
  <v-row align="center">
    <v-col class="py-0">
      <v-row align="center">
        <v-col :class="[isHeaderContacts ? 'white--text': 'secondary--text font-weight-medium', 'text-no-wrap pr-2']">
          Горячая линия:
        </v-col>
        <v-col>
          <a
            :class="[isHeaderContacts ? 'white--text': 'secondary--text', 'font-weight-medium-plus text-no-wrap link link-hover-red']"
            :href="`tel:${$consts.COMPANY_PHONE}`"
          >{{ $consts.COMPANY_PHONE }}</a>
        </v-col>
        <v-col class="white--text">
          <a
            :class="[isHeaderContacts ? 'white--text': 'secondary--text', 'font-weight-medium-plus text-no-wrap link link-hover-red']"
            href="mailto:help@dengabank.ru"
          >{{ $consts.COMPANY_HELP_EMAIL }}</a>
        </v-col>
      </v-row>
    </v-col>
    <v-col md="auto">
      <base-button
        class="link justify-start"
        color="primary"
        min-width="126"
        :to="{name: 'Help'}"
        rounded
      >
        <v-icon class="pr-3">
          {{ mdiHelpCircleOutline }}
        </v-icon>Помощь
      </base-button>
    </v-col>
    <v-col md="auto">
      <base-button
        color="primary"
        :to="{name: 'Map'}"
        rounded
      >
        <v-icon class="pr-3">
          {{ mdiMapMarker }}
        </v-icon>Адреса отделений
      </base-button>
    </v-col>
  </v-row>
</template>

<script>
import { mdiHelpCircleOutline, mdiMapMarker } from '@mdi/js';
import BaseButton from './base-components/BaseButton.vue';

export default {
  name: 'Contacts',
  components: {
    BaseButton,
  },
  props: {
    isHeaderContacts: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiHelpCircleOutline,
      mdiMapMarker,
    };
  },
};
</script>

<style lang="scss">
   .contacts {
      @include ff_semibold;

      &__title {
         margin-right: 23px;
         color: $color-red;
         font-size: 18px;
      }

      &-item {
         margin-right: 19px;

         &:last-child {
            margin-right: 0;
         }

         a {
            text-decoration: none;
            font-size: 16px;
            color: $color-dark-blue;
         }

         &_help {
            a {
               color: $color-red;
               width: auto;
               padding: 2px 34px;
               display: block;
               font-size: 14px;
            }
         }

         &_callback {
            i {
               padding-right: 6px;
            }
         }

         &_email {
            a {
               transition: color .2s;

               &:hover, &:focus {
                  color: $color-red;
               }
            }
         }

         &_main-site {
            a {
               font-size: 12px;
               color: $color-red;
            }
         }
      }

      &-list {
         display: flex;
         justify-content: space-between;
         align-items: center;
      }
   }
</style>
