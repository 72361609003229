<template>
  <footer
    ref="footer"
    class="footer secondary"
  >
    <v-container class="py-2 py-lg-5">
      <v-row class="d-lg-flex justify-space-between flex-column flex-lg-row">
        <v-col cols="auto">
          <a
            class="white--text link link-hover-white"
            href="https://denga.ru/info"
            target="_blank"
          >Заемщику</a>
        </v-col>
        <v-col cols="auto">
          <footer-contacts />
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

import FooterContacts from './FooterContacts.vue';

export default {
  components: {
    FooterContacts,
  },
  data() {
    return {
      menu: [
        {
          parentLabel: 'Продукты',
          parentLink: 'GetLoan',
        },
        {
          parentLabel: 'Заём на карту',
          parentLink: 'GetLoan',
        },
        {
          parentLabel: 'О компании',
          parentLink: 'GetLoan',
        }, {
          parentLabel: 'Докуенты',
          parentLink: 'GetLoan',
        },
        {
          parentLabel: 'Новости',
          parentLink: 'GetLoan',
        },
        {
          parentLabel: 'Заёмщику',
          parentLink: 'GetLoan',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'Auth/isLoggedIn',
    }),
    ...mapState({
      borrowerData: state => state.borrowerData,
    }),
  },
};

</script>
<style lang="scss">
  .footer {
    .container {
      max-width: 1120px !important;
      padding-left: 12px !important;
      padding-right: 12px !important;
    }

    .logo {
      max-width: 100px  ;
    }
  }

</style>
