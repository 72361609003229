<template>
  <div
    class="mb-4"
  >
    <base-multi-error-extractor
      v-if="$attrs.hasOwnProperty('validator') && $attrs.validator.$anyError && !onlyServerErrors"
      v-bind="$attrs"
    >
      <template #default="{ errorMessage }">
        <error
          class="mb-1"
        >
          {{ errorMessage }}
        </error>
      </template>
    </base-multi-error-extractor>
    <server-errors v-else-if="errors.length > 0" />
  </div>
</template>

<script>
import { templates } from 'vuelidate-error-extractor';

import {
  mapState,
} from 'vuex';

import Error from '@/components/errors/Error.vue';
import ServerErrors from './ServerErrors.vue';

export default {
  components: {
    baseMultiErrorExtractor: templates.multiErrorExtractor.baseMultiErrorExtractor,
    Error,
    ServerErrors,
  },
  inheritAttrs: false,
  props: {
    onlyServerErrors: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      errors: state => state.errors,
    }),
  },
};
</script>

<style lang="scss">
</style>
