<template>
  <div
    v-if="!isBorrowerDataEmpty"
    class="hero text-center pb-4"
  >
    <div class="text-h5 font-weight-medium-plus mb-2">
      {{ !isHavePersonalData ? 'Гость' : `${borrowerData.last_name} ${borrowerData.first_name} ${borrowerData.patronymic || '' }` }}
    </div>
    <div class="mb-2">
      <span v-if="!isHavePersonalData">
        Вам недоступно получение займов
      </span>
    </div>
    <div class=" pb-1">
      <div class="d-flex align-center justify-center">
        <div>{{ getIdentificationStatusName }}</div>
        <div class="pl-1">
          <identification-status-icon />
        </div>
      </div>
    </div>
    <div>
      <router-link
        v-if="borrowerData.identification_status === -1"
        :to="{name: 'IdentificationForm'}"
        class="link link-hover-red link-bordered"
      >
        Пройти снова
      </router-link>
      <router-link
        v-if="borrowerData.identification_status === 0"
        :to="{name: 'IdentificationForm'}"
        class="link link-hover-red link-bordered"
      >
        Пройти идентификацию
      </router-link>
      <router-link
        v-if="borrowerData.identification_status === 1"
        :to="{name: 'IdentificationResult'}"
        class="link link-hover-red link-bordered"
      >
        Результаты
      </router-link>
    </div>
  </div>
</template>
<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

import IdentificationStatusIcon from './IdentificationStatusIcon.vue';

export default {
  name: 'Hero',
  components: {
    IdentificationStatusIcon,
  },
  data() {
    return {
      icon: null,
      name: null,
      iconClass: null,
      iconPath: null,
    };
  },
  computed: {
    ...mapState({
      borrowerData: state => state.borrowerData,
    }),
    ...mapGetters({
      getIdentificationStatusName: 'Identification/getIdentificationStatusName',
      isBorrowerDataEmpty: 'isBorrowerDataEmpty',
    }),
    isHavePersonalData() {
      return this.borrowerData.first_name !== null && this.borrowerData.last_name !== null;
    },
  },
};

</script>
<style  lang="scss">
    .hero {
        border-bottom: 1px solid #bdc2c8;

        &__avatar {
            margin-bottom: 6px;
        }

        &-verification {
            &-status {
                display: flex;
                justify-content: center;
                align-items: center;

                &__icon {
                    display: flex;
                    padding-left: 6px;

                    &_refresh {
                        cursor: pointer;
                    }
                }
            }
        }

        &__name {
            font-size: 24px;
            font-weight: 600;
        }

        &__description {
            margin-bottom: 10px;
        }

        &-verification {
            &__status {
                @include ff_light;
            }
        }
    }
</style>
