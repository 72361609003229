import { mapState } from 'vuex';

import {
  isClientDate, isServerDate, isClientPhone, isServerPhone,
} from '@/helpers/functions';

import modals from './modals';
import modifiers from './modifiers';

export default {
  mixins: [modals, modifiers],
  validations() {
    /*
    * Рекурсивно собираются все поля из компоненты и рекурсия собирает из них сериализатор
    * */
    const fields = this.getAllFieldsByState(this.fields, 'validations');

    return {
      fields,
    };
  },
  computed: {
    ...mapState('GetLoan', {
      loanSteps: state => state.loanSteps,
    }),
  },
  methods: {
    scrollToFirstError() {
      this.$nextTick(() => {
        const domRect = document.querySelector('.form-field--error').getBoundingClientRect();

        window.scrollTo(
          domRect.left + document.documentElement.scrollLeft,
          domRect.top - domRect.height - 50 + document.documentElement.scrollTop,
        );
      });

      return false;
    },
    onFocusPhoneField(e) {
      if (e.target.value.length === 0) {
        this.fields.phone.value = '+7';
      }
    },
    async getSteps() {
      /*
      * В ЛК есть система так называемых "шагов", в админке лк есть система которая позволяет указывать в
      * каком порядке и какие пункты анкеты пользователю надо будет указывать в процесе взятия займа, из за
      * чего понадобилась данная схема,в localStorage сохраняется id выбранного займа, затем в параметрах роутинга
      * указывается флаг что необходимо получить шаги для заполнения анкеты
      * */

      if (this.loanSteps || !this.$route.meta.steps) return;
      // Для того что бы определять что переходи был совершен по шагам заполнения анкеты в оформлении займа
      // и что бы при отсутствии шагов был редирект на страницу loan-options
      if (!this.loanSteps && this.$route.meta.type === 'create') {
        const body = localStorage.getItem('currentProductLine');

        try {
          await this.$store.dispatch('GetLoan/START', JSON.parse(body));
        } catch (e) {
          this.$router.push({ name: 'LoanProducts' });
        }
      } else if (!this.loanSteps && this.$route.meta.type === 'edit') {
        try {
          await this.$store.dispatch('GetLoan/EDIT_LOAN');
        } catch (e) {
          await this.$router.push({ name: 'LoansList' });
        }
      }
    },

    nextStep(falseFunction) {
      /*
      * Функция проверяет есть ли в хранилище массив шагов прохождения анкеты, если нет то выполняется falseFunction
      * которая передается при вызове функции в самой компоненте.
      * */
      let nextStep = null;
      if (!this.loanSteps || !this.$route.meta.steps) {
        falseFunction();
        return;
      }

      for (const step in this.loanSteps) {
        if (this.loanSteps[step] === this.$route.name) {
          nextStep = +step + 1;
        }
      }

      this.$router.push({
        name: this.loanSteps[nextStep],
      });
    },
    getAllFieldsByState(singleField, valueFlag, prepareForServer = false, combineFields = true) {
      /*
    * Рекурсия собирает все поля в один объект
    *
    * @valueFlag - необходим для валидации, и для корректной отправки данных на сервер, если value === 'value',
    * то конечный объект будет сформирован без дополнительного объекта value, если не будет указан, то будет сформирован объект как и в стейте с дополнительным объектом value.
    *
    * @prepareForServer - флаг указывает на то, необходимо ли полям проходить
    * дополнителный проверки( в данный момент это телефон и дата ) перед отправкой на сервер.
    *
    * @combineFields - флаг указывает на то объединять ли поля в один объект(необходимо для валидации),
    * по дефолту поля будут скомпонованы в таком же порядке как они указаны в state
    * */

      const fields = {};

      const recursiveParseFields = (object, parent, value, unitFields, forServer) => {
        for (const field in object) {
          if (object[field] === null) {
            return;
          }

          if (object[field].hasOwnProperty('value')) {
            if (!object[field].hasOwnProperty(value)) continue;
            const objectField = object[field];
            let fieldValue = objectField[value];
            if (typeof fieldValue === 'string') {
              /* В ПО есть уязвимость связанная с юникод символами,
               из за чего у пользователя ломалась анкета,
               появилась задача что бы все поля проверялись на некоторые юникод символы, и затем удалять их. */
              fieldValue = fieldValue.replace(/[«»]/g, '');
            }

            if (fieldValue === '' && value === 'value') {
              /* На бэк необходимо отправлять именно null значение, пустая строка может получится когда пользователь
              что то ввел в поле, потом все стер, и тогда в поле будет не null ,а пустая строка, */
              fieldValue = null;
            }

            if (fieldValue && fieldValue.hasOwnProperty('id')) {
              fieldValue = fieldValue.id;
            }
            if (forServer) {
              if (isClientDate(fieldValue)) {
                fieldValue = this.convertDateForServer(fieldValue);
              } else if (isClientPhone(fieldValue)) {
                fieldValue = this.convertPhoneForServer(fieldValue);
              }
            }

            if (value === 'value') {
              parent[field] = fieldValue;
            } else {
              parent[field] = {
                value: fieldValue,
              };
            }
          } else if (Object.keys(object[field]).length > 0) {
            parent[field] = {};

            recursiveParseFields(object[field], parent[field], value, unitFields, forServer);
          }
        }
      };

      recursiveParseFields(singleField, fields, valueFlag, combineFields, prepareForServer);

      return fields;
    },
    fillData(result, responseData) {
      /*
    * Рекурсия для наполнения форм компоненты данными
    * */
      const recursiveFillData = (res, data) => {
        for (const field in res) {
          if (typeof res[field] !== 'object') {
            if (field === 'passport_whom_code' && res[field] && !res[field].includes('-')) {
              res[field] = [res[field].slice(0, 3), '-', res[field].slice(3)].join('');
            }
            if (field === 'recent_step' || field === 'loan_purpose' || field === 'main_phone_number' || field === 'identification_status') continue;
            if (!data?.hasOwnProperty(field)) continue;
            if (data[field].hasOwnProperty('type') && data[field].type === 'selector') {
              data[field].value = {
                id: res[field],
              };
            } else if (data[field].type === 'photo') {
              data[field].src = res[field];
            } else if (isServerDate(res[field])) {
              data[field].value = this.convertDateForClient(res[field]);
            } else if (isServerPhone(res[field])) {
              data[field].value = this.convertPhoneForClient(res[field]);
            } else {
              data[field].value = res[field];
            }
          } else {
            recursiveFillData(res[field], data[field]);
          }
        }
      };

      recursiveFillData(result, responseData);
    },

    getSalaryRangeIdByOfficialSalary(salary, salaryRangesList) {
      const salaryRange = salaryRangesList.find(element => Number(salary) >= Number(element.salary_range[0]) && Number(salary) <= Number(element.salary_range[1]));

      if (salaryRange) {
        return salaryRange.id;
      }

      const highSalary = salaryRangesList.find(element => element.salary_range[0] && !element.salary_range[1]);

      if (highSalary) {
        return highSalary.id;
      }

      return salaryRangesList.find(element => !element.salary_range[0] && !element.salary_range[1]).id;
    },
  },
};
