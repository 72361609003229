import {
  JOB_DATA,
  PERSONAL_ADDRESS,
  PERSONAL_DATA,
  CAR_DOCUMENTS_PHOTOS,
  CAR_PHOTOS,
  CAR_INFO,
  FULL_USER_INFO,
  PASSPORT_PHOTOS, PASSPORT_PHOTOS_META,
  PROFILE_DOCS,
  FULL_USER_INFO_USER,
} from '@/helpers/js/urls';

const state = {};
const getters = {};
const mutations = {};
const actions = {
  // Profile/PASSPORT_PHOTOS
  async SEND_PASSPORT_PHOTOS({
    dispatch,
  }, data) {
    await dispatch('API', {
      method: 'post',
      url: PASSPORT_PHOTOS,
      body: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }, { root: true });
  },
  async SEND_PASSPORT_PHOTOS_META({
    dispatch,
  }, data) {
    await dispatch('API', {
      method: 'post',
      url: PASSPORT_PHOTOS_META,
      body: data,
    }, { root: true });
  },
  async GET_PASSPORT_PHOTOS({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: PASSPORT_PHOTOS,
    }, { root: true });
  },
  async GET_DOCUMENTS({
    dispatch,
  }) {
    return Promise.all([dispatch('API', {
      method: 'get',
      url: PROFILE_DOCS,
    }, { root: true }), dispatch('API', {
      method: 'get',
      url: PASSPORT_PHOTOS,
    }, { root: true })]);
  },
  async SEND_DOCUMENTS({
    dispatch,
  }, data) {
    await dispatch('API', {
      method: 'post',
      url: PROFILE_DOCS,
      body: data,
      preloader: true,
    }, { root: true });
  },
  // Profile/JOB
  async SEND_JOB_DATA({
    dispatch,
  }, data) {
    await dispatch('API', {
      method: 'post',
      url: JOB_DATA,
      body: data,
      preloader: true,
    }, { root: true });
  },
  async GET_JOB_DATA({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: JOB_DATA,
    }, { root: true });
  },
  // Profile/ADDRESS
  async SEND_PERSONAL_ADDRESS({
    dispatch,
  }, data) {
    await dispatch('API', {
      method: 'post',
      url: PERSONAL_ADDRESS,
      body: data,
      preloader: true,
    }, { root: true });
  },
  async GET_PERSONAL_ADDRESS({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: PERSONAL_ADDRESS,
    }, { root: true });
  },
  // Profile/PERSONAL
  async SEND_PERSONAL_DATA({
    dispatch,
  }, data) {
    await dispatch('API', {
      method: 'post',
      url: PERSONAL_DATA,
      body: data,
      preloader: true,
    }, { root: true });
  },
  async GET_PERSONAL_DATA({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: PERSONAL_DATA,
    }, { root: true });
  },
  // Profile/CAR_DOCS
  async SEND_CARD_DOCS({
    dispatch,
  }, data) {
    await dispatch('API', {
      method: 'post',
      url: CAR_DOCUMENTS_PHOTOS,
      body: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }, { root: true });
  },
  async GET_CARD_DOCS({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: CAR_DOCUMENTS_PHOTOS,
    }, { root: true });
  },
  // Profile/CAR_PHOTOS
  async SEND_CAR_PHOTOS({
    dispatch,
  }, data) {
    return dispatch('API', {
      method: 'post',
      url: CAR_PHOTOS,
      body: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }, { root: true });
  },
  async GET_CAR_PHOTOS({
    dispatch,
  }, data) {
    return dispatch('API', {
      method: 'get',
      url: CAR_PHOTOS,
      body: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }, { root: true });
  },
  // Profile/CAR_INFO
  async SEND_CAR_INFO({
    dispatch,
  }, data) {
    await dispatch('API', {
      method: 'post',
      url: CAR_INFO,
      body: data,
    }, { root: true });
  },
  async GET_CAR_INFO({
    dispatch,
  }) {
    // eslint-disable-next-line no-return-await
    return dispatch('API', {
      method: 'get',
      url: CAR_INFO,
    }, { root: true });
  },
  async FULL_USER_INFO({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: FULL_USER_INFO,
    }, { root: true });
  },
  async FULL_USER_INFO_PROFILE({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: FULL_USER_INFO_USER,
    }, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
