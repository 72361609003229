<template>
  <div class="wrapper">
    <v-app>
      <Preloader v-if="preloader" />
      <div class="page-container">
        <custom-header />
        <div class="main">
          <div class="container container-flex">
            <Sidebar v-if="isLoggedIn" />
            <div
              :class="{'main-content-nosidebar': sidebarInvisible}"
              class="main-content"
            >
              <h2
                v-if="$route.meta.title || $route.meta.isConfirmTitle"
                :class="isLoggedIn ? 'section-title' : $route.meta.smallTitle ?
                  'page-title page-title_32' : 'page-title'"
                v-html="$route.meta.isConfirmTitle ? confirmationText : $route.meta.title"
              />
              <router-view />
            </div>
          </div>
        </div>
      </div>
      <custom-footer />
      <result-modal
        success
        name="success-payment"
      >
        {{ $vuetify.lang.t('$vuetify.SUCCESS_PAYMENT') }}
      </result-modal>
      <result-modal
        error
        name="reject-payment"
      >
        {{ $vuetify.lang.t('$vuetify.REJECTED_PAYMENT') }}
      </result-modal>
      <result-modal
        error
        no-image
        name="network-error"
      >
        <h2 class="mb-4">
          Ошибка сети!
        </h2>
        Проверьте подключение к сети и активные телефонные вызовы, если есть, завершите их.<br>
        Не обновляя страницу попробуйте ввести код еще раз.
      </result-modal>
      <google-form-pool />
    </v-app>
    <refresh-page-modal v-if="refreshPageModalVisible" />
  </div>
</template>
<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import { VApp } from 'vuetify/lib';
import GoogleFormPool from '@/components/GoogleFormPool.vue';
import { stringParamsToObject } from '@/helpers/functions';
import CustomHeader from './components/header/CustomHeader.vue';
import CustomFooter from './components/footer/CustomFooter.vue';
import store from './store';
import Sidebar from './components/Sidebar.vue';

import maskInit from './helpers/js/masks';
import Preloader from './components/Preloader.vue';
import modals from './helpers/mixins/modals';
import RefreshPageModal from './helpers/modals/RefreshPageModal.vue';

import ResultModal from './helpers/modals/ResultModal.vue';

export default {
  name: 'App',
  components: {
    CustomHeader,
    CustomFooter,
    Sidebar,
    VApp,
    Preloader,
    ResultModal,
    GoogleFormPool,
    RefreshPageModal,
  },
  mixins: [modals],
  computed: {
    ...mapGetters({
      isLoggedIn: 'Auth/isLoggedIn',
      confirmationText: 'confirmationText',
      isDirectoryDataEmpty: 'isDirectoryDataEmpty',
    }),
    ...mapState({
      preloader: state => state.preloader,
      directoryData: state => state.directoryData,
      refreshPageModalVisible: state => state.refreshPageModalVisible,
    }),
    sidebarInvisible() {
      return !this.isLoggedIn || this.$route.meta.hideSidebar || this.isLanding;
    },
    isLanding() {
      return this.$route.fullPath.includes('/lp');
    },
    isBest2PayResponsePage() {
      return this.$route.fullPath.includes('/best2pay');
    },
    isMandarinResponsePage() {
      return this.$route.fullPath.includes('/mandarin_response');
    },
    isQiwiResponsePage() {
      return this.$route.fullPath.includes('/qiwi_response');
    },
    isEsiaAuthPage() {
      return this.$route.fullPath.includes('/esia_auth');
    },
  },
  async created() {
    this.$http.interceptors.response.use(undefined, err => new Promise(() => {
      if (err.message === 'Network Error') {
        this.$router.push({
          name: 'Maintenance',
        });
        throw err;
      } else {
        if ((err.response.status === 401)) {
          store.dispatch('Auth/ENTRY_ERROR');
        }
        if (err.response.status === 404) {
          this.$router.push({
            name: '404',
          });
        }
      }

      throw err;
    }));

    await this.sendLeadParams();

    if (this.isDirectoryDataEmpty) {
      await this.$store.dispatch('GET_DIRECTORY_DATA');
    }
  },
  updated() {
    maskInit();
  },
  mounted() {
    maskInit();
  },
  methods: {
    async sendLeadParams() {
      // Лиды отправлять только для ЛК и только если есть параметры
      if (!this.isLanding
          && !this.isBest2PayResponsePage
          && window.location.search
          && !this.isMandarinResponsePage
          && !this.isQiwiResponsePage
          && !this.isEsiaAuthPage) {
        localStorage.setItem('lead_sent', 'true');
        // получаем токен для отправки лида
        await this.$store.dispatch('GET_LEAD_TOKEN');
        const params = stringParamsToObject(window.location.search);

        try {
          // пробуем отправить лид
          await this.$store.dispatch('PUT_LEAD_PARAMS', { ...params });
        } catch (e) {
          // если не получилось, пробуем получить новый токен и отправить еще раз.
          // у токена ограниченый срок жизни, если он закончится, придет ошибка, по этому нужно получить его еще раз
          localStorage.removeItem('lead_token');
          await this.$store.dispatch('GET_LEAD_TOKEN');
          await this.$store.dispatch('PUT_LEAD_PARAMS', { ...params });
        }
      }
    },
  },
};

</script>
<style lang="scss">
@import '@/helpers/styles/styles';
</style>
