import {
  IDENTIFICATION_DATA, IDENTIFICATION_CREATE_CREATE,
} from '@/helpers/js/urls';

import consts from '@/helpers/consts';

const state = {
  identificationStatus: null,
};
const getters = {
  getIdentificationStatusName(state) {
    const { identificationStatus } = state;

    switch (identificationStatus) {
    case -1:
      return consts.IDENTIFICATION.STATUSES.ERROR;
    case 1:
      return consts.IDENTIFICATION.STATUSES.IN_PROCESS;
    case 2:
      return consts.IDENTIFICATION.STATUSES.ADVANCED_IDENTIFICATION;
    case 3:
      return consts.IDENTIFICATION.STATUSES.ADVANCED_IDENTIFICATION;
    default:
      return consts.IDENTIFICATION.STATUSES.NO_IDENTIFICATION;
    }
  },
};
const mutations = {
  setIdentificationStatus(state, payload) {
    state.identificationStatus = payload;
  },
};
const actions = {
  async GET_IDENTIFICATION_DATA({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: IDENTIFICATION_DATA,
    }, { root: true });
  },
  async SEND_IDENTIFICATION_DATA({
    dispatch,
  }, data) {
    await dispatch('API', {
      method: 'post',
      url: IDENTIFICATION_CREATE_CREATE,
      body: data,
      preloader: true,
    }, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
