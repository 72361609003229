function getServer() {
  return process.env.VUE_APP_SERVER_URL || document.location.origin;
}

module.exports = {
  SERVER: getServer(),
  CHECK_PHONE: '/api/v1/auth/registration/phone/',
  NEW_PASSWORD: '/api/v1/auth/registration/final/',
  SEND_SMS: '/api/v1/auth/registration/check_sms/',
  CHECK_PASSPORT: '/api/v1/auth/registration/passport/',
  LOGIN: '/api/v1/auth/login/',
  CHECK_PRIMARY_BORROWER: '/api/v1/auth/check_primary_borrower/',
  LOGIN_CHECK: '/api/v1/auth/login/check/',
  USER_LOGOUT: '/api/v1/auth/logout/',
  RESET_PASSWORD_CHECK_PHONE: '/api/v1/auth/reset_password/',
  RESET_PASSWORD_CHECK_PASSPORT: '/api/v1/auth/reset_password/passport/',
  RESET_PASSWORD_SMS: '/api/v1/auth/reset_password/check_sms/',
  RESET_PASSWORD_CHANGE: '/api/v1/auth/reset_password/change/',
  SUMMS_LIST: '/api/v1/get_loan/summs_list/',
  PERIOD_LIST: '/api/v1/get_loan/periods_list/',
  PAYMENT_OPTIONS: '/api/v1/get_loan/options/',
  SAVE_LOAN: '/api/v1/get_loan/start/',
  WAY_PAYOUT: '/api/v1/profile/way_payout/',
  PASSPORT_PHOTOS: '/api/v1/profile/passport_photo/',
  CAR_DOCUMENTS_PHOTOS: '/api/v1/get_loan/car_document_photos/',
  CAR_INFO: '/api/v1/get_loan/car_info/',
  PERSONAL_DATA: '/api/v1/profile/personal_info/',
  PERSONAL_ADDRESS: '/api/v1/profile/personal_address/',
  DIRECTORY_DATA: '/api/v1/common/directory_data/',
  JOB_DATA: '/api/v1/profile/job_income/',
  RESEND_SMS: '/api/v1/common/resend_sms/',
  CONFIRM_SMS: '/api/v1/profile/full_info_profile/',
  FULL_USER_INFO: '/api/v1/profile/full_info_profile/',
  FULL_USER_INFO_USER: '/api/v1/profile/user_full_info_profile/',
  ADDITIONAL_LOAN_INFO: '/api/v1/get_loan/additional_loan_info/',
  URL_STEPS: '/api/v1/common/urls_steps/',
  SAVE_BORROWER: '/api/v1/profile/save_borrower_profile/',
  IDENTIFICATION_CREATE_CREATE: '/api/v1/identification/create/',
  IDENTIFICATION_DATA: '/api/v1/identification/create/',
  GET_ALL_LOANS: '/api/v1/loans/get_loans/',
  GET_ACTIVE_LOAN: '/api/v1/loans/get_active_loan/',
  CREATE_LOAN: '/api/v1/get_loan/create_loan/',
  EDIT_LOAN: '/api/v1/get_loan/get_loan_edit/',
  BORROWER_DATA: '/api/v1/common/common_borrower_data/',
  REGISTRATION_FINAL: '/api/v1/auth/registration/final/',
  LOAN_PRODUCTS_LIST: '/api/v1/get_loan/products_list/',
  GET_PERSONAL_OFFERS: '/api/v1/get_loan/get_personal_offers/',
  GET_DOCUMENT: '/api/v1/common/get_document/',
  CREATE_PAYMENT_DOC: '/api/v1/payment/pay_doc/',
  CANCEL_LOAN: '/api/v1/get_loan/get_loan_cancel/',
  CAR_PHOTOS: '/api/v1/get_loan/add_car_photo/',
  CARD_BINDING: '/api/v1/payment/card_binding_request/',
  CHECK_CARD: '/api/v1/payment/check_card/',
  OFFICES: '/api/v1/common/offices/',
  LOAN_DOCS: '/api/v1/get_loan/loan_documents/',
  SIGNING_DOCUMENT: '/api/v1/get_loan/sign_documents/',
  LOAN_PRODUCT_INFO: '/api/v1/get_loan/products_list/',
  PAY_REQUEST: '/api/v1/payment/payin_request/',
  FEEDBACK_THEMES: '/api/v1/feedback/help/',
  SET_EMAIL: '/api/v1/payment/set_email/',
  PAYMENT_SMS: '/api/v1/payment/payment_sms/',
  GET_ALL_LOANS_DOCS: '/api/v1/loans/get_loan_docs/',
  GET_UTM_FIELDS_FOR_REFUSAL_LOAN: '/api/v1/loans/get_utm_fields_for_refusal_loan/',
  GET_CERTIFICATE_OF_ABSENCE_OF_DEBT_EXIST: '/api/v1/common/check_statement/',
  PASSPORT_PHOTOS_META: '/api/v1/profile/passport_photos_meta/',
  PAYMENT_CARD_PHOTO: '/api/v1/payment/card_photo/',
  LOAN_CALLBACK: '/api/v1/get_loan/loan_request_call/',
  HELP_TOPICS: '/api/v1/common/help_topics/',
  LOAN_STATEMENT: '/api/v1/common/statement/',
  CHECK_CONTRACT_DATA: '/api/v1/auth/check_contract_data/',
  CHANGE_USER_PHONE: '/api/v1/auth/change_user_phone/',
  CONFIRM_CODE: '/api/v1/auth/confirm_code/',
  ADVERTISING_SETTINGS: '/api/v1/profile/mailing_settings/',
  CONTRAGENT_INFO: '/api/v1/insurance/get_contragent/',
  CREATE_CONTRAGENT: '/api/v1/insurance/create_statement/',
  INSURANCE_SIGN_DOCUMENT: '/api/v1/insurance/sign_docs/',
  INSURANCE_PAY_IN: '/api/v1/insurance/pay_in/',
  INSURANCE_OFERTA_DOC: '/api/v1/insurance/get_card_insurance_doc/',
  PROFILE_DOCS: '/api/v1/profile/passport_info/',
  INITIAL: '/api/v1/initial/',
  INITIAL_PERSONAL_INFO: '/api/v1/initial/personal_info/',
  INITIAL_PASSPORT: '/api/v1/initial/passport/',
  INITIAL_ADDRESS: '/api/v1/initial/address/',
  INITIAL_LOAN_ESIA_ADDITIONAL_INFO: '/api/v1/initial/additional_info_and_address/',
  INITIAL_ADDITIONAL_INFO: '/api/v1/initial/additional_info/',
  INITIAL_LOAN_SUM: '/api/v1/initial/settings/get_summ/',
  INITIAL_LOAN_PERIOD: '/api/v1/initial/settings/get_period?summ=',
  INITIAL_LOAN_OPTIONS: '/api/v1/initial/settings/get_options?summ={sum}&period={period}',
  INITIAL_LOAN_PHOTO: '/api/v1/initial/passport_photo/',
  INITIAL_LOAN_FINALIZE_AGREE: '/api/v1/initial/finalize/additional_agreements/',
  INITIAL_LOAN_FINALIZE_CODE: '/api/v1/initial/finalize/check_code/',
  INITIAL_LOAN_FINALIZE_PASSWORD: '/api/v1/initial/finalize/set_password/',
  INITIAL_LOAN_FINALIZE_BINDCARG: '/api/v1/initial/finalize/bind_card/',
  BRAINY_SOFT_REGISTRATION_URL: 'https://lk.zaim-mi.ru/registration',
  BRAINY_SOFT_AUTH_URL: 'https://lk.zaim-mi.ru/auth',
  INITIAL_LK_LEAD: '/api/v1/initial/lk_lead/',
  INITIAL_LK_LEAD_PERSONAL_INFO: '/api/v1/initial/lk_lead/personal_info/',
  INITIAL_RESEND_SMS: '/api/v1/initial/finalize/resend_code/',
  GRAYLOG_COLLECT_DATA_URL: '/api/v1/common/graylog_data/',
  COMMON_SUMMS_LIST: '/api/v1/get_loan/common_summs_list/',
  FETCH_ESIA_AUTH_URL: '/api/v1/esia/auth_url/',
  ESIA_LOGIN: '/api/v1/esia/login/',
};
