import Inputmask from 'inputmask';

export default function maskInit({ onCompleteFieldDate } = {}) {
  const date = new Inputmask({
    alias: 'datetime',
    inputFormat: 'dd.mm.yyyy',
    placeholder: '__.__.____',
    separator: '.',
    leapday: '29.02.',
    jitMasking: true,
    isComplete(buffer, opts) {
      const isFieldFilled = new RegExp(opts.regex).test(buffer.join(''));

      if (isFieldFilled && typeof onCompleteFieldDate === 'function') {
        onCompleteFieldDate();
      }
    },
  });

  const currency = new Inputmask({
    regex: '^[0-9]+(\\.[0-9]{1,2})?$',
    placeholder: '0',
  });

  const phone = new Inputmask({
    mask: '+7(999)999-99-99',
  });

  currency.mask(document.querySelectorAll('.input-currency .v-text-field__slot input'));
  date.mask(document.querySelectorAll('.input-date .v-text-field__slot input'));
  phone.mask(document.querySelectorAll('.input-phone .v-text-field__slot input'));
}
