import { routingLazyLoad } from '@/helpers/functions';

const profileRoutes = [
  {
    path: '/profile/',
    component: routingLazyLoad('Cabinet'),
    meta: {
      sidebar: true,
      requiresAuth: true,
    },
    children: [
      {
        path: 'documents',
        name: 'PassportPhotos',
        component: routingLazyLoad('ProfileDocuments', 'Profile'),
        meta: {
          title: 'Документы',
          sidebar: true,
        },
      },
      {
        path: 'personal',
        name: 'Personal',
        component: routingLazyLoad('ProfilePersonal', 'Profile'),
        meta: {
          title: 'Контактная информация',
          sidebar: true,
        },
      },
      {
        path: 'address',
        name: 'Address',
        component: routingLazyLoad('ProfileAddress', 'Profile'),
        meta: {
          title: 'Адреса',
          sidebar: true,
        },
      },
      {
        path: 'job',
        name: 'Job',
        component: routingLazyLoad('ProfileJob', 'Profile'),
        meta: {
          title: 'Занятость и доходы',
          sidebar: true,
        },
      },
      {
        path: 'full-info',
        name: 'FullInfo',
        component: routingLazyLoad('ProfileFullInfo', 'Profile'),
        meta: {
          sidebar: true,
          title: 'Проверка данных',
        },
      },
    ],
  },
];

export default profileRoutes;
