import { routingLazyLoad } from '@/helpers/functions';

const authRoutes = [
  {
    path: '/',
    name: 'Login',
    meta: {
      title: 'Личный кабинет для займов',
    },
    component: routingLazyLoad('LogIn'),
    props: true,
  },
  {
    path: '/password',
    name: 'LoginPassword',
    meta: {
      title: 'Личный кабинет для займов',
    },
    component: routingLazyLoad('LoginPassword'),
    props: true,
  },
  {
    path: '/check',
    name: 'AuthSMS',
    component: routingLazyLoad('SmsPage'),
    props: {
      dispatchType: 'Auth/LOGIN_CHECK',
      timerType: 'login',
      startOverPage: 'Login',
    },
    meta: {
      isConfirmTitle: true,
      smallTitle: true,
    },
  },
];

export default authRoutes;
