import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import { BrowserTracing } from '@sentry/tracing';
import router from '@/router/Router';

export function initSentry() {
  // если в .env dns для Sentry нет, ошибки трекать не будет
  if (!process.env.VUE_APP_SENTRY_DNS) {
    return;
  }

  Sentry.init({
    environment: window.location.origin === 'https://lk.denga.ru' ? 'production' : 'test',
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],

    tracesSampleRate: 0.2,
    tracingOptions: {
      trackComponents: true,
    },

    beforeSend(event, hint) {
      if (event.exception && event.exception.values[0] && event.exception.values[0].value
          && (event.exception.values[0].value.includes('401')
          || event.exception.values[0].value.includes('403')
          || event.exception.values[0].value.includes('400')
          || event.exception.values[0].value.includes('429')
          || hint.originalException === 'Timeout')) {
        return null;
      }

      // filter out UnhandledRejection errors that have no information
      if (event.exception !== undefined && event.exception.values !== undefined
          && event.exception.values.length === 1) {
        const e = event.exception.values[0];
        if (e.type === 'UnhandledRejection' && e.value === 'Non-Error promise rejection captured with value: ') {
          return null;
        }
      }

      return event;
    },
    ignoreErrors: [
      'Non-Error promise rejection captured',
      'Network request failed',
      'Failed to fetch',
      'Network Error',
      'Unexpected token \'<\'',
      'yaCounter',
      'withrealtime/messaging',
      'Loading CSS chunk',
      'ChunkLoadError',
      'Loading chunk',
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      'Can\'t find variable: ZiteReader',
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
}

export function addApiLog({
  state, error, request, getters,
}) {
  // если в .env dns для Sentry нет, ошибки трекать не будет
  if (!process.env.VUE_APP_SENTRY_DNS) {
    return;
  }

  Sentry.withScope(scope => {
    const response = error.response;
    if (!getters.isBorrowerDataEmpty) {
      scope.setUser({ borrower: state.borrowerData });
    }

    scope.setTag('server error', response && response?.status);
    scope.setLevel('warning');
    scope.setContext('Request', {
      url: request.url.replace(/\//g, '//'), // сентри фильтрует ссылки, по этому меняем / на //
      method: request.method,
      body: request.body,
      headers: request.headers,
    });
    scope.setContext('Response', {
      status: response?.status,
      data: error.response && JSON.stringify(error.response?.data),
      response: error.response && JSON.stringify(error.response),
      message: error.message,
    });
    Sentry.captureException(new Error(`Server Request Error ${response?.status}`));
  });
}

export function photoLoadLog(name, type, size, error, tag = '') {
  if (!process.env.VUE_APP_SENTRY_DNS) {
    return;
  }

  Sentry.withScope(scope => {
    scope.setTag('Photo Load Error', 'loading Photo');
    scope.setContext('File', {
      name, fileType: type, size, error: JSON.stringify(error),
    });
    Sentry.captureException(new Error(`Photo Load Error ${tag}`));
  });
}
