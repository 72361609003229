import { routingLazyLoad } from '@/helpers/functions';

const changePhoneRoutes = [
  {
    path: '/change-phone/check/',
    name: 'ChangePhoneCheck',
    component: routingLazyLoad('ChangePhoneCheck'),
    meta: {
      title: 'Изменение номера телефона',
    },
  },
  {
    path: '/change-phone/form/',
    name: 'ChangePhoneForm',
    component: routingLazyLoad('ChangePhoneForm'),
    meta: {
      canNotGoFromUrl: true,
      title: 'Изменение номера телефона',
    },
  },
  {
    path: '/change-phone/sms/',
    name: 'ChangeAuthSMS',
    component: routingLazyLoad('SmsPage'),
    props: {
      dispatchType: 'Auth/CONFIRM_CODE',
      timerType: 'change_phone',
    },
    meta: {
      isConfirmTitle: true,
      smallTitle: true,
    },
  },
];

export default changePhoneRoutes;
