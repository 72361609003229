<template>
  <div
    v-if="errors.length > 0"
    class="error-box"
  >
    <error
      v-for="(item, index) in errors"
      :key="index"
    >
      {{ item.message[0] }}
    </error>
    <slot />
  </div>
</template>

<script>
import {
  mapState,
} from 'vuex';
import error from './errors/Error.vue';

export default {
  name: 'ServerErrors',
  components: {
    error,
  },
  computed: {
    ...mapState({
      errors: state => state.errors,
    }),
  },
};
</script>

<style scoped>

</style>
