<template>
  <div
    v-bind="$attrs"
    class="error-container d-flex"
  >
    <div class="error-container__icon pr-2 d-flex align-start">
      <span>
        <img src="@/assets/images/icons/unverified.svg">
      </span>
    </div>
    <div class="orange--text">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error',
};
</script>

<style scoped lang="scss">
.error-container {
  &__icon {
    min-width: 20px;
  }
}
</style>
