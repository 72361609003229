export default class Converters {
  static convertDateForServer(date) {
    const splitDate = date.split('.');

    return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
  }

  static convertPhoneForServer(phone) {
    return phone.replace(/[-()]/g, '');
  }

  static convertPhoneForClient(phone, lp = false) {
    const cleaned = (`${phone}`).replace(/\D/g, '');
    const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      const intlCode = (match[1] ? `+${match[1]}` : '+7');

      if (lp) {
        return `${intlCode}(${match[2]})${match[3]}-${match[4]}${match[5]}`;
      }
      return `${intlCode}(${match[2]})${match[3]}-${match[4]}-${match[5]}`;
    }
  }

  static convertNumberToCopybookString(_number, cents) {
    // TODO: старый код с overflow надо бы отрефакторить
    // сумма прописью для чисел от 0 до 999 триллионов
    // можно передать параметр "валюта": RUB,USD,EUR (по умолчанию RUB)
    /* eslint-disable */
    var toUpper = toUpper || false;
    const _arr_numbers = [];
    _arr_numbers[1] = ['', 'один', 'два', 'три', 'четыре', 'пять', 'шесть', 'семь', 'восемь', 'девять', 'десять', 'одиннадцать', 'двенадцать', 'тринадцать', 'четырнадцать', 'пятнадцать', 'шестнадцать', 'семнадцать', 'восемнадцать', 'девятнадцать'];
    _arr_numbers[2] = ['', '', 'двадцать', 'тридцать', 'сорок', 'пятьдесят', 'шестьдесят', 'семьдесят', 'восемьдесят', 'девяносто'];
    _arr_numbers[3] = ['', 'сто', 'двести', 'триста', 'четыреста', 'пятьсот', 'шестьсот', 'семьсот', 'восемьсот', 'девятьсот'];

    function number_parser(_num, _desc) {
      let _string = '';
      let _num_hundred = '';
      if (_num.length == 3) {
        _num_hundred = _num.substr(0, 1);
        _num = _num.substr(1, 3);
        _string = `${_arr_numbers[3][_num_hundred]} `;
      }
      if (_num < 20) _string += `${_arr_numbers[1][parseFloat(_num)]} `;
      else {
        const _first_num = _num.substr(0, 1);
        const _second_num = _num.substr(1, 2);
        _string += `${_arr_numbers[2][_first_num]} ${_arr_numbers[1][_second_num]} `;
      }
      switch (_desc) {
      case 0:
        if (_num.length == 2 && parseFloat(_num.substr(0, 1)) == 1) {
          _string += 'рублей';
          break;
        }
        var _last_num = parseFloat(_num.substr(-1));
        if (_last_num == 1) _string += 'рубль';
        else if (_last_num > 1 && _last_num < 5) _string += 'рубля';
        else _string += 'рублей';
        break;
      case 1:
        _num = _num.replace(/^[0]{1,}$/g, '0');
        if (_num.length == 2 && parseFloat(_num.substr(0, 1)) == 1) {
          _string += 'тысяч ';
          break;
        }
        // eslint-disable-next-line no-redeclare
        var _last_num = parseFloat(_num.substr(-1));
        if (_last_num == 1) _string += 'тысяча ';
        else if (_last_num > 1 && _last_num < 5) _string += 'тысячи ';
        else if (parseFloat(_num) > 0) _string += 'тысяч ';
        _string = _string.replace('один ', 'одна ');
        _string = _string.replace('два ', 'две ');
        break;
      case 2:
        _num = _num.replace(/^[0]{1,}$/g, '0');
        if (_num.length == 2 && parseFloat(_num.substr(0, 1)) == 1) {
          _string += 'миллионов ';
          break;
        }
        // eslint-disable-next-line no-redeclare
        var _last_num = parseFloat(_num.substr(-1));
        if (_last_num == 1) _string += 'миллион ';
        else if (_last_num > 1 && _last_num < 5) _string += 'миллиона ';
        else if (parseFloat(_num) > 0) _string += 'миллионов ';
        break;
      case 3:
        _num = _num.replace(/^[0]{1,}$/g, '0');
        if (_num.length == 2 && parseFloat(_num.substr(0, 1)) == 1) {
          _string += 'миллиардов ';
          break;
        }
        // eslint-disable-next-line no-redeclare
        var _last_num = parseFloat(_num.substr(-1));
        if (_last_num == 1) _string += 'миллиард ';
        else if (_last_num > 1 && _last_num < 5) _string += 'миллиарда ';
        else if (parseFloat(_num) > 0) _string += 'миллиардов ';
        break;
      }
      return _string;
    }

    function decimals_parser(_num) {
      if (!cents) return '';
      const _first_num = _num.substr(0, 1);
      const _second_num = parseFloat(_num.substr(1, 2));
      let _string = ` ${_first_num}${_second_num}`;
      if (_second_num == 1) _string += ' копейка';
      else if (_second_num > 1 && _second_num < 5) _string += ' копейки';
      else _string += ' копеек';
      return _string;
    }

    if (!_number || _number == 0) return false;
    if (typeof _number !== 'number') {
      _number += '';
      _number = _number.replace(',', '.');
      _number = parseFloat(_number);
      if (isNaN(_number)) return false;
    }
    _number = _number.toFixed(2);
    if (_number.indexOf('.') != -1) {
      const _number_arr = _number.split('.');
      // eslint-disable-next-line no-redeclare
      var _number = _number_arr[0];
      var _number_decimals = _number_arr[1];
    }
    const _number_length = _number.length;
    let _string = '';
    let _num_parser = '';
    let _count = 0;
    for (let _p = (_number_length - 1); _p >= 0; _p--) {
      const _num_digit = _number.substr(_p, 1);
      _num_parser = _num_digit + _num_parser;
      if ((_num_parser.length == 3 || _p == 0) && !isNaN(parseFloat(_num_parser))) {
        _string = number_parser(_num_parser, _count) + _string;
        _num_parser = '';
        _count++;
      }
    }
    if (_number_decimals) _string += decimals_parser(_number_decimals);
    if (toUpper === true || toUpper == 'upper') {
      _string = _string.substr(0, 1).toUpperCase() + _string.substr(1);
    }
    return _string.replace(/[\s]{1,}/g, ' ');
  }
  /* eslint-disable */

  static convertDateForClient(date) {
    const splitDate = date.split('-');
    return `${splitDate[2]}.${splitDate[1]}.${splitDate[0]}`;
  }

  static getDayAndMonthName(date) {
    if (!date) {
      return date;
    }

    const splitDate = date.split('-');
    const unFormatDate = new Date(splitDate[0], splitDate[1] - 1, splitDate[2]);
    return unFormatDate.toLocaleString('ru', {
      month: 'long',
      day: 'numeric',
    });
  }

  static dateLabelConverter(date) {
    return this.declOfNum(Math.abs(date), ['день', 'дня', 'дней']);
  }

  static declOfNum(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
  }
}
