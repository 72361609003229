<template>
  <div class="error-box">
    <error
      v-for="(item, index) in errors"
      :key="index"
    >
      <div v-if="showGlobalErrors && errors.length > 0">
        {{ item.message[0] }}
      </div>
    </error>
    <slot />
  </div>
</template>

<script>
import {
  mapState,
} from 'vuex';

import error from './Error.vue';

export default {
  name: 'ErrorBox',
  components: {
    error,
  },
  props: {
    showGlobalErrors: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      errors: state => state.errors,
    }),
  },
};
</script>

<style scoped lang="scss">
</style>
