<template>
  <modal
    v-bind="$attrs"
    classes="vm--modal_default"
    v-on="$listeners"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <div class="default-modal">
      <div class="default-modal__close">
        <button
          type="button"
          @click="closeModal"
        >
          <fai icon="times" />
        </button>
      </div>
      <div class="default-modal__wrapper">
        <div class="default-modal__content">
          <slot />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'DefaultModal',
  methods: {
    closeModal() {
      this.$modal.hide(this.$attrs.name);
      this.$emit('close');
    },
    beforeOpen() {
      setTimeout(() => {
        const wrapper = document.querySelector('.vm--overlay');

        if (wrapper) {
          wrapper.classList.add('blured');
        }
      }, 1000);
    },
    beforeClose() {
      const wrapper = document.querySelector('.vm--overlay');

      if (wrapper) {
        wrapper.classList.remove('blured');
      }
    },
  },
};
</script>

<style lang="scss">

  .vm--modal_default {
    box-shadow: none !important;
    border-radius: 0;
    background-color: transparent !important;
    padding: 12px;
    display: flex;
    justify-content: center;
  }

  .vm--modal_photo {
      .default-modal {
        background-color: transparent;
        box-shadow: none;

        &__close {
          right: 33px;
          top: 0;
          color:$color-white
        }
      }
  }

   .default-modal {
     position: relative;
     padding: 24px 32px;
     background-color: #fff;
     box-shadow: $default-shadow;
     border-radius: $default-border-radius;
     display: inline-block;

     &__close {
       position: absolute;
       color: $color-disabled;
       right: 15px;
       top: 7px;
       font-size: 19px;
       display: flex;
       transition: color .2s;

       &:hover {
         color: $color-black;
       }
     }

     .block {
       padding: 24px 32px;
     }
   }
</style>
