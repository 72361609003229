<template>
  <aside class="sidebar">
    <Hero />
    <custom-menu :menu="menu" />
    <div class="sidebar-contacts">
      <Contacts />
    </div>
  </aside>
</template>
<script>
import { mapState } from 'vuex';

import CustomMenu from './CustomMenu.vue';
import Hero from './Hero.vue';
import Contacts from './Contacts.vue';

export default {
  name: 'Sidebar',
  components: {
    CustomMenu,
    Hero,
    Contacts,
  },
  data() {
    return {
      menu: [
        {
          parentLabel: 'Взять заём',
          parentLink: 'LoanProducts',
          icon: 'icon-donate',
        }, {
          parentLabel: 'Мои займы',
          parentLink: 'LoansList',
          icon: 'icon-wallet',
        }, {
          parentLabel: 'Анкета',
          parentLink: 'Personal',
          icon: 'icon-profile',
          editProfileLink: true,
          childrens: [
            {
              to: 'Personal',
              text: 'Контактная информация',
            },
            {
              to: 'PassportPhotos',
              text: 'Документы',
            },
            {
              to: 'Address',
              text: 'Адреса',
            },
            {
              to: 'Job',
              text: 'Занятость и доходы',
            },
            {
              to: 'FullInfo',
              text: 'Проверка данных',
            },
          ],
        },
        {
          parentLabel: 'АнтиКоронаВирус',
          parentLink: 'InsuranceProducts',
          icon: 'icon-heart',
        },
        {
          parentLabel: 'Адреса отделений',
          parentLink: 'Map',
          icon: 'icon-map',
        },
        {
          parentLabel: 'Помощь',
          parentLink: 'Help',
          icon: 'icon-faq',
        },
        {
          parentLabel: 'Написать нам',
          parentLink: 'Feedback',
          icon: 'icon-email',
        },
        {
          parentLabel: 'Настройки',
          parentLink: 'Settings',
          icon: 'icon-settings',
        },
      ],
    };
  },
  computed: {
    ...mapState('GetLoan', {
      securityComment: state => state.securityComment,
    }),
    ...mapState({
      borrowerData: state => state.borrowerData,
      directoryData: state => state.directoryData,
      loanSteps: state => state.loanSteps,
    }),
  },

  methods: {
    logout() {
      this.$store.dispatch('User/LOGOUT');
    },
  },

};

</script>
<style lang="scss">
    .sidebar {
        position: fixed;
        width: 268px;
        z-index: 100;

        &-contacts {
            display: none;
        }

        @media screen and (max-height: 768px) {
            margin-top: 0;
        }

        @media screen and (max-width: 1120px) {
            max-width: 240px;
        }

        @media screen and (max-width: 1264px) {
            min-width: 320px;
            position: fixed;
            min-height: 77vh;
            box-shadow: -2px 20px 20px #0000003b;
            z-index: 200;
            overflow-y: scroll;
            top: 71px;
            bottom: 0;
            border-radius: 0;
            left: 0;
            padding: 24px 31px 24px;
            background-color: #f6f6f8;
            transform: translate(-115%, 0);
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

            &-contacts {
                display: block;

                .contacts__title {
                    margin-bottom: 15px;
                    font-size: 16px;
                }

                .contacts-list {
                    padding: 15px 0;
                    flex-wrap: wrap;
                }

                .contacts-item {
                    margin-bottom: 15px;
                    margin-right: 0;

                    a {
                        font-size: 14px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &_main-site {
                        margin-top: 20px;
                        flex-basis: 100%;

                        a {
                            display: block;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
</style>
