<template>
  <div
    v-if="!isBorrowerDataEmpty"
    class="menu"
  >
    <div class="menu-list">
      <div
        v-for="(item,index) in menu"
        :key="index"
        class="menu-item "
      >
        <div
          v-if="item.editProfileLink && borrowerData.edit_profile && borrowerData.active_loan"
          class="menu-item-parent"
        >
          <a
            class="menu-item-parent__link black--text"
            @click="editLoan"
          ><i :class="item.icon" />Анкета</a>
        </div>
        <router-link
          v-else
          v-slot="{ navigate }"
          :to="{name:item.parentLink}"
          :class="item.hasOwnProperty('childrens') ? 'menu-item-parent menu-item-parent_root' : 'menu-item-parent'"
          custom
          active-class="active"
        >
          <div
            role="link"
            @click="navigate"
            @keypress.enter="navigate"
          >
            <a class="menu-item-parent__link black--text"><i :class="item.icon" /><span>{{ item.parentLabel }}</span></a>
            <div
              v-if="item.hasOwnProperty('childrens')"
              class="menu-item-sub"
            >
              <div class="menu-item-sub__inner">
                <div class="menu-item-sub__list">
                  <div
                    v-for="(subItem, subIndex) in item.childrens"
                    :key="subIndex"
                    class="menu-item-sub__item"
                  >
                    <div v-if="item.childrens">
                      <router-link
                        :to="{name:subItem.to}"
                        class="menu-item-sub__link black--text"
                        exact="exact"
                        active-class="active"
                      >
                        {{ subItem.text }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="menu-item">
        <div class="menu-item-parent">
          <a
            class="menu-item-parent__link black--text"
            @click="logout"
          >
            <i class="icon-logout" />
            <span>Выход</span>
          </a>
        </div>
      </div>
    </div>
    <result-modal
      name="cant-edit-profile"
      error
    >
      {{ $vuetify.lang.t('$vuetify.PROFILE.CANT_EDIT_PROFILE') }}
    </result-modal>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import modals from '@/helpers/mixins/modals';
import ResultModal from '../helpers/modals/ResultModal.vue';

export default {
  components: {
    ResultModal,
  },
  mixins: [modals],
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      borrowerData: state => state.borrowerData,
    }),
    ...mapState('GetLoan', {
      loanSteps: state => state.loanSteps,
    }),
    ...mapGetters({
      isBorrowerDataEmpty: 'isBorrowerDataEmpty',
    }),
  },
  methods: {
    editLoan() {
      this.$store.dispatch('GetLoan/EDIT_LOAN').then(() => {
        if (this.loanSteps) {
          this.$router.push({
            name: this.loanSteps[0],
          });
        } else {
          this.$modal.show('cant-edit-profile');
        }
      });
    },
    logout() {
      this.$store.dispatch('Auth/LOGOUT');
    },
  },

};

</script>
<style lang="scss">
    .menu {
        &-list {
            border-bottom: 1px solid #bdc2c8;
            padding: 10px 0;
        }

        &-item {
            margin-bottom: 5px;

            &:nth-last-child(2) {
                margin-top: 7px;
                padding-top: 7px;
                border-top: 1px solid #bdc2c8;
            }

            &-disabled {
                .menu-item-parent__link {
                    cursor: not-allowed;
                    color: #ccc;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            &-parent {
                position: relative;

                &_root {
                    &:before {
                        content: '';
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        z-index: 1;
                        transform: translateY(-50%);
                        width: 20px;
                        height: 20px;
                        background: url('../assets/images/arrow-down.png') center center no-repeat;
                        background-size: cover;
                        transition: transform .2s;
                    }

                    &:hover, &:focus {
                        &:before {
                            transform: translateY(-50%) rotate(-90deg);
                        }
                    }

                    @media screen and (max-width: 1024px) {
                        .menu-item-sub {
                            padding: 15px 22px;
                        }

                        .menu-item-sub__inner {
                            height: 100%;
                            overflow: unset;
                        }
                        &:before {
                            display: none;
                        }
                    }
                }

                &:hover, &:focus {
                    &:after {
                        content: '';
                        position: absolute;
                        right: -10px;
                        top: 0;
                        height: 100%;
                        width: 20px;
                    }

                    .menu-item-parent__link {
                        background-color: #fff;

                        &:before {
                            content: '';
                            position: absolute;
                            left: -24px;
                            width: 24px;
                            top: 0;
                            bottom: 0;
                            background-color: #fff;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            left: -32px;
                            width: 8px;
                            top: 0;
                            bottom: 0;
                            background-color: $color-red;
                        }
                    }

                    .menu-item-sub {
                        opacity: 1;
                        z-index: 150;
                        right: -206px;
                    }
                }

                &.active {
                    .menu-item-parent__link {
                        background-color: #fff;

                        &:before {
                            content: '';
                            position: absolute;
                            left: -24px;
                            width: 24px;
                            top: 0;
                            bottom: 0;
                            background-color: #fff;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            left: -32px;
                            width: 8px;
                            top: 0;
                            bottom: 0;
                            background-color: $color-red;
                        }
                    }
                }

                &__link {
                    text-decoration: none;
                    color: #000;
                    font-size: 20px;
                    position: relative;
                    display: flex;
                    cursor: pointer;
                    padding: 7px 0;
                    transition: all .2s;
                    align-items: center;

                    i {
                        padding-right: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 18px;
                    }
                }

                @media screen and (max-width: 1024px) {
                    &:hover, &:focus {
                        .menu-item-sub {
                            right: 0;
                            z-index: 0;
                        }

                        &:after {
                            display: none;
                        }
                    }
                }
            }

            &-sub {
                position: absolute;
                right: 9999px;
                top: 0;
                opacity: 0;
                z-index: -10;
                max-width: 207px;
                background-color: #fff;
                padding: 15px 22px;
                transition: opacity .2s;

                &__item {
                    margin-bottom: 17px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &__link {
                    text-decoration: none;
                    color: $color-dark-blue;
                    font-size: 16px;
                    @include ff_semibold;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        width: 5px;
                        height: 22px;
                        left: -32px;
                        bottom: 0;
                        transform: translateY(-50%);
                        background-color: transparent;
                        transition: all .2s;
                    }

                    &:hover {
                        &:after {
                            background-color: $color-red;
                        }
                    }

                    &.active {
                        border-bottom: 2px solid $color-red;
                    }
                }

                @media screen and (max-width: 1024px) {
                    position: relative;
                    right: 0;
                    z-index: 0;
                    opacity: 1;
                    background-color: transparent;
                    max-width: 100%;
                    padding: 0;

                    &__inner {
                        height: 0;
                        overflow: hidden;
                    }

                    &__link {
                        &.active {
                            border-bottom: 0;

                            &:after {
                                content: '';
                                position: absolute;
                                left: -37px;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 24px;
                                height: 2px;
                                background-color: $color-red;
                            }
                        }
                    }
                }
            }
        }
    }

</style>
