import moment from 'moment';

import {
  CREATE_PAYMENT_DOC,
  GET_ACTIVE_LOAN,
  GET_ALL_LOANS,
  GET_ALL_LOANS_DOCS,
  LOAN_STATEMENT,
  GET_UTM_FIELDS_FOR_REFUSAL_LOAN,
  GET_CERTIFICATE_OF_ABSENCE_OF_DEBT_EXIST,
} from '@/helpers/js/urls';
import { getCountOfDaysAfterDate, getDifference, getSum } from '@/helpers/functions';

const INSURANCE_AGREEMENT = 'Настоящим прошу ООО «МФК «ЮПИТЕР 6» заключить со мной <a href="{ofertaUrl}" target="_blank">Соглашение-оферту</a> в отношении моей '
    + 'банковской карты, с помощью которой осуществляется погашение займа. Услуга, оказываемая ООО «МФК '
    + '«ЮПИТЕР 6» согласно Соглашения-оферты включает в себя консультирование по условиям Программы '
    + 'страхования добровольного коллективного страхования рисков, связанных с использованием пластиковых '
    + 'карт (действующая редакция Программы страхования доступна на сайте Страховщика <a href="https://www.d2insur.ru/" target="_blank">www.d2insur.ru/</a> и в '
    + 'Соглашении-Оферте), сбор, обработку и техническую передачу информации о принадлежащей '
    + 'Выгодоприобретателю (мне) карте и Выгодоприобретателе Страхователю с целью последующей передачи '
    + 'Страховщику, связанную с организацией включения застрахованной карты в Список застрахованных карт '
    + 'по Договору коллективного страхования № Д2/УКДкарт/11/23. (заключенного между '
    + 'Страхователем – ООО «УК Деньга» и страховщиком – '
    + 'АО «Д2 Страхование») (Далее – Договор коллективного страхования). Заключая Соглашение-Оферту, '
    + 'прошу ООО «УК Деньга» осуществить страхование, связанное с использованием пластиковых '
    + 'карт, в АО «Д2 Страхование». Настоящим поручаю ООО «МФК «ЮПИТЕР 6» застраховать банковскую '
    + 'карту. Условия страхования указаны в Программе страхования, в том числе включают риски:<br/>'
    + '- несанкционированное списание в результате утраты Застрахованной карты;<br/>'
    + '- несанкционированное списание без утраты Застрахованной карты;<br/>'
    + '- хищение наличных денежных средств, полученных по Застрахованной карте;<br/>'
    + '- оплата банковских комиссий при утрате Застрахованной карты;<br/>'
    + '- утрата официальных документов вместе с Застрахованной картой<br/>'
    + 'Стоимость предлагаемой услуги по Соглашению-Оферте составляет 100 (сто) рублей 00 копеек.<br/>'
    + 'Выгодоприобретателем по договору страхования является держатель карты.<br/>'
    + 'Акцептуя Соглашение-Оферту, я проинформирован о том, что за дополнительными разъяснениями я могу '
    + 'обратиться с соответствующим запросом в адрес ООО «УК Деньга» 199397, город Санкт-Петербург, улица Кораблестроителей, дом 32, корпус 1, литера А, помещение 38Н, комната 1. Я проинформирован о том, что ознакомиться с '
    + 'условиями страхования (в том числе, с Программой добровольного коллективного страхования рисков, '
    + 'связанных с использованием пластиковых карт), а также обратиться за разъяснениями о структуре '
    + 'страховой премии, о составе услуг АО «Д2 Страхование» я могу на сайте <a href="https://www.d2insur.ru/" target="_blank">www.d2insur.ru/</a> или '
    + 'обратиться с соответствующим письмом по адресу 630099, г. Новосибирск, ул. Депутатская, д.2, помещ.1.<br/>'
    + 'Подробные условия Соглашения-оферты об оказании услуги включения в Список застрахованных лиц по '
    + 'Программе добровольного коллективного страхования, связанных с использованием пластиковых карт, для '
    + 'ознакомления опубликованы в <a href="{ofertaUrl}" target="_blank">Оферте</a>. Настоящим соглашаюсь, что факт оплаты данной услуги является '
    + 'подтверждением подписания соглашения-оферты.<br/> '
    + 'Я ознакомлен с условиями страхования, в том числе с Соглашением-офертой, Программой страхования. ';

const state = {
  loanActive: null,
  loanListHistory: null,
  isLoanEnd: null,
  isPaymentDay: null,
  loanListHistoryPreloader: false,
  currentSummPayment: null,
  loanActiveStatusName: null,
  loanActiveStatusMessage: null,
  loanActiveExpiredSum: null,
  successOperations: {},
  expiredDates: [],
  expiredOperations: [],
  daysToSale: null,
  futureDates: [],
  deferredOperations: null,
  positiveLoanStatus: [0, 1, 3, 4, 6, 7, 11, 12, 14],
  negativeLoanStatus: [2, 8, 9, 5, 10, 13],
  insuranceCost: 0,
  insuranceAgree: INSURANCE_AGREEMENT,
  isLoanRequested: false,
};
const getters = {
  getCurrentBody: state => {
    if (!state.loanActive) return false;

    if (state.loanActive.is_annuity) {
      return !state.isLoanEnd ? state.loanActive.summ_body : state.loanActive.current_summ_expired;
    }
    return state.loanActive.summ;
  },
  getCurrentExpired: state => {
    if (!state.loanActive) return false;

    return state.loanActive.is_annuity ? state.loanActive.current_summ_expired : state.loanActive.summ_max;
  },
  getCurrentPayment: state => {
    if (!state.loanActive) return false;

    if (!state.loanActive.is_expired) {
      return state.loanActive.is_annuity ? state.loanActive.full_summ_payment : state.loanActive.summ_max;
    }

    if (state.loanActive.is_annuity) {
      return state.isLoanEnd ? state.loanActive.current_summ_expired : state.loanActive.full_summ_payment;
    }

    return state.loanActive.summ_max;
  },
  getRecommendPayment: state => {
    const loan = state.loanActive;

    if (!loan) return false;

    const daysAfterContractExpiration = getCountOfDaysAfterDate(loan.date_maturity_additional);

    if (loan.is_annuity) {
      if (loan.is_expired) {
        return daysAfterContractExpiration >= 0 ? loan.summ : loan.current_summ_expired;
      }
      if (state.isPaymentDay) {
        return state.loanActive.full_summ_payment;
      }

      return daysAfterContractExpiration === 0 ? loan.summ : false;
    }

    return loan.is_expired || state.isPaymentDay
      ? getDifference(+state.loanActive.summ_max, +state.loanActive.summ_noncash)
      : false;
  },

};
const mutations = {
  ALL_LOANS_REQUEST: state => {
    state.loanListHistoryPreloader = true;
    state.loanListHistory = null;
  },
  ACTIVE_LOAN_REQUEST: state => {
    state.loanActive = null;
    state.daysToSale = null;
    state.loanActiveExpiredSum = null;
    state.loanActiveStatusMessage = null;
    state.loanActiveStatusName = null;
  },
  LOAN_OPERATIONS: state => {
    const paymentsList = state.loanActive.list_of_payments || null;
    state.successOperations = {
      summ: 0,
      date: null,
    };
    state.expiredDates = [];
    state.futureDates = [];
    state.expiredOperations = [];
    state.deferredOperations = [];

    if (state.loanActive.hasOwnProperty('deferred_ops_list') && state.loanActive.deferred_ops_list.length > 0) {
      for (const ops in state.loanActive.deferred_ops_list) {
        state.deferredOperations.push(state.loanActive.deferred_ops_list[ops]);
        state.deferredOperations[ops].summ = getSum(parseFloat(state.loanActive.deferred_ops_list[ops].summ_body), parseFloat(state.loanActive.deferred_ops_list[ops].summ_percents), parseFloat(state.loanActive.deferred_ops_list[ops].summ_penalty));
        state.deferredOperations[ops].summ = state.deferredOperations[ops].summ.toFixed(2);
      }
    }

    for (const item in paymentsList) {
      if (paymentsList[item].is_expired && !paymentsList[item].is_operation) {
        state.expiredDates.push(paymentsList[item]);
      } else if (!paymentsList[item].is_expired && !paymentsList[item].is_operation) {
        state.futureDates.push(paymentsList[item]);
      } else if (!paymentsList[item].is_expired && paymentsList[item].is_operation) {
        state.successOperations.summ += +paymentsList[item].summ;
        state.successOperations.date = paymentsList[item].date;
      } else if (paymentsList[item].is_expired && paymentsList[item].is_operation) {
        state.expiredOperations.push(paymentsList[item]);
      }
    }

    state.successOperations.summ = state.successOperations.summ.toFixed(2);
    state.expiredOperations.reverse();
  },
  LOAN_STATUS_MESSAGE: state => {
    /*
              * Расшифровка status_numbers
              * 0 - Подтверждение заемщиком
              * 1 - Доработка
              * 2 - Отказ
              * 3 - Одобрен
              * 4 - Выдан
              * 5 - Погашен
              * 6 - Подтвержден
              * 7 - Подтверждение заемщиком
              * 8 - Отменен
              * 9 - Списание долга
              * 11 - Предверительно одобрен
              * 12 - Подписан
              * 13 - Выплата не прошла
              * */
    if (state.loanActive.status_number === 4) {
      if (state.isPaymentDay) {
        state.loanActiveStatusMessage = `<img src="${require('@/assets/images/icons/unverified.svg')}"/> Не забудьте внести платеж сегодня! Иначе ${state.daysToSale} заём будет передан в сервисную компанию.`;
      } else {
        switch (state.loanActive.collector_status) {
        /*
                              * Расшифровка collector_statuses
                              * 1 - Не просрочен
                              * 2 - Просрочен, но не продан,
                              * 3 - Просрочен и продан,
                              * 4 - Не просрочен , но продан
                              * */
        case 1:
          state.loanActiveStatusMessage = `<img src="${require('@/assets/images/icons/verified-active.svg')}"/> Все в порядке!`;
          break;
        case 2:
          state.loanActiveStatusMessage = `<img src="${require('@/assets/images/icons/unverified-error.svg')}"/> <b>Внимание!</b> У Вас имеется просроченная задолженность в размере: <span class="loan-item__expired-summ">${state.loanActiveExpiredSum} руб.</span> Срочно погасите, иначе ${state.daysToSale} заём будет передан в сервисную компанию!`;
          break;
        case 3:
        case 4:
          state.loanActiveStatusMessage = `<img src="${require('@/assets/images/icons/unverified-error.svg')}"/> <b>Внимание!</b> Ваш заём продан в ${state.loanActive.service_company_name} (Подробнее: <a href="${state.loanActive.service_company_url}" class="link-red" target="_blank">${state.loanActive.service_company_url}</a>). Просроченная задолженность на текущий момент составляет: <span class="loan-item__expired-summ">${state.loanActiveExpiredSum} руб.</span>. Информация о задолженности предоставлена на основании агентского договора с ${state.loanActive.service_company_name}.`;
          break;
        default:
          break;
        }
      }
    } else {
      switch (state.loanActive.status_number) {
      case 0:
        state.loanActiveStatusMessage = `<img src="${require('@/assets/images/icons/verified-active.svg')}"/> Обращение будет рассмотрено до 00:00 (по московскому времени)`;
        break;
      case 1:
        if (!state.loanActive.can_edit) {
          state.loanActiveStatusMessage = `Для получения займа Вам необходимо посетить ${state.loanActive.loan_office_name} (${state.loanActive.loan_office_address}), в котором Вы оформляли заявку.`;
        }
        break;
      case 3:
        if (state.loanActive.can_issued) {
          state.loanActiveStatusMessage = `<img src=" ${require('@/assets/images/icons/unverified.svg')}"/> Вам необходимо принять решение по данному обращению до 00:00 (по московскому времени), иначе заявка будет отменена.`;
        } else {
          state.loanActiveStatusMessage = `Для получения займа Вам необходимо посетить ${state.loanActive.loan_office_name} (${state.loanActive.loan_office_address}), в котором Вы оформляли заявку.`;
        }
        break;
      case 12:
        state.loanActiveStatusMessage = `<img src="${require('@/assets/images/icons/verified-active.svg')}"/> Денежные средства поступят на Вашу банковскую карту в период от 5 минут до 24 часов. Сроки зачисления зависят от банка, который выпустил Вашу карту.`;
        break;
      default:
        state.loanActiveStatusMessage = null;
      }
    }
  },
  LOAN_STATUS_NAME: state => {
    if (state.loanActive.status_number === 4) {
      if (state.loanActive.is_expired) {
        state.loanActiveStatusName = `Заём просрочен! <img src="${require('@/assets/images/icons/unverified-error.svg')}"/>`;
      } else if (state.isPaymentDay) {
        state.loanActiveStatusName = `Сегодня день оплаты <img src="${require('@/assets/images/icons/unverified.svg')}"/>`;
      } else {
        state.loanActiveStatusName = `${state.loanActive.status_name} <img src="${require('@/assets/images/icons/verified-active.svg')}"/>`;
      }
    } else {
      state.loanActiveStatusName = `${state.loanActive.status_name} <img src="${require('@/assets/images/icons/verified-active.svg')}"/>`;
    }
  },
  CURRENT_EXPIRED: state => {
    if (state.loanActive.is_annuity) {
      state.loanActiveExpiredSum = state.loanActive.current_summ_expired;
    } else {
      state.loanActiveExpiredSum = state.loanActive.summ_max;
    }
  },
  DAYS_TO_SALE: state => {
    const loanDate = new Date(state.loanActive.is_annuity ? state.loanActive.date_maturity_real_initially : state.loanActive.date_maturity_additional);

    if (state.loanActive.hasOwnProperty('days_to_sale')) {
      loanDate.setDate(loanDate.getDate() + state.loanActive.days_to_sale);
    }

    state.daysToSale = `${+loanDate.getDate() < 10 ? `0${loanDate.getDate()}` : loanDate.getDate()}.${+loanDate.getMonth() + 1 < 10 ? `0${+loanDate.getMonth() + 1}` : loanDate.getMonth() + 1}.${loanDate.getFullYear()}`;
  },
  IS_PAYMENT_DAY: state => {
    const loanDate = state.loanActive.is_annuity
      ? state.loanActive.date_maturity_real_initially
      : state.loanActive.date_maturity_additional;
    const now = moment().format('YYYY-MM-DD');
    state.isPaymentDay = moment(now).isSame(loanDate);
  },
  IS_LOAN_END: state => {
    state.isLoanEnd = getCountOfDaysAfterDate(state.loanActive.date_maturity_additional) >= 1;
  },
  ACTIVE_LOAN: (state, data) => {
    state.loanActive = data;
    state.insuranceCost = data && data.card_insurance_summ;
  },
  ALL_LOANS: (state, data) => {
    state.loanListHistoryPreloader = false;

    if (data) {
      state.loanListHistory = data.loans.reverse();
    }
  },
  IS_LOAN_REQUESTED: (state, data) => {
    state.isLoanRequested = data;
  },
};
const actions = {
  async ACTIVE_LOAN({
    commit,
    dispatch,
  }) {
    commit('ACTIVE_LOAN_REQUEST');
    try {
      const response = await dispatch('API', {
        method: 'get',
        url: GET_ACTIVE_LOAN,
        preloader: true,
      }, { root: true });

      const loan = response.data.data;

      if (response.status === 202) {
        commit('IS_LOAN_REQUESTED', true);
        return;
      }

      commit('ACTIVE_LOAN', loan);

      if (!loan) return;

      commit('LOAN_OPERATIONS');
      commit('IS_LOAN_END');
      commit('DAYS_TO_SALE');
      commit('IS_PAYMENT_DAY');
      commit('CURRENT_EXPIRED');
      commit('LOAN_STATUS_MESSAGE');
      commit('LOAN_STATUS_NAME');
      commit('IS_LOAN_REQUESTED', false);
    } catch (rej) {
      return rej.response.data;
    }
  },
  async ALL_LOANS({
    commit,
    dispatch,
    rootState,
  }) {
    commit('ALL_LOANS_REQUEST');
    const response = await dispatch('API', {
      method: 'get',
      url: GET_ALL_LOANS,
    }, { root: true });
    if (rootState.errors.length > 0) return;
    const { data } = response.data;
    commit('ALL_LOANS', data);
  },
  async CREATE_PAYMENT_DOC({
    dispatch,
  }, data) {
    const response = await dispatch('API', {
      method: 'post',
      url: CREATE_PAYMENT_DOC,
      responseType: 'blob',
      body: data,
    }, { root: true });
    const blob = new Blob([response.data], {
      type: 'application/pdf',
    });
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = 'payment-document.pdf';
    document.body.appendChild(link);

    link.click();
  },
  async LOAN_STATEMENT({
    dispatch,
  }, data) {
    const response = await dispatch('API', {
      method: 'get',
      responseType: 'blob',
      url: `${LOAN_STATEMENT}?token=${data.token}`,
    }, { root: true });

    if (!response) return;

    const blob = new Blob([response.data], {
      type: 'application/pdf',
    });
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = 'statement-document.pdf';
    document.body.appendChild(link);

    link.click();
  },
  async GET_LOAN_DOCS({
    dispatch,
  }, data) {
    return dispatch('API', {
      method: 'get',
      url: `${GET_ALL_LOANS_DOCS + data}/`,
    }, { root: true });
  },
  async GET_UTM_FIELDS_FOR_REFUSAL_LOAN({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: GET_UTM_FIELDS_FOR_REFUSAL_LOAN,
    }, { root: true });
  },
  async GET_CERTIFICATE_OF_ABSENCE_OF_DEBT_EXIST({
    dispatch,
  }, data) {
    return dispatch('API', {
      method: 'get',
      preloader: true,
      url: `${GET_CERTIFICATE_OF_ABSENCE_OF_DEBT_EXIST}?token=${data.token}`,
    }, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
