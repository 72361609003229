// TODO: Нужно перенести заменить эти константы на конастанты из лэнговая файла vuetify
export default {
  COMPANY_PHONE: '8 800 700 08 08',
  COMPANY_HELP_EMAIL: 'help@dengabank.ru',
  SESSION_EXPIRED: 'Сессия устарела. Проверка кода невозможна, начните заново.',
  IDENTIFICATION: {
    STATUSES: {
      ERROR: 'Ошибка идентификации',
      IN_PROCESS: 'В процессе',
      SIMPLE_IDENTIFICATION: 'Упрощенно идентифицирован',
      ADVANCED_IDENTIFICATION: 'Идентифицирован',
      NO_IDENTIFICATION: 'Не идентифицирован',
    },
  },
  GENDERS: {
    DEFAULT: 'Не выбрано',
    MALE: 'Мужской',
    FEMALE: 'Женский',
  },
  PAYMENT: {
    TYPES: {
      CASH: 'Наличными',
      CARD: 'На карту',
      COMMON: 'На карту / Наличными',
    },
  },
  PAYMENT_SYSTEM: {
    BEST2PAY: 'Best2Pay',
    MANDARIN: 'Mandarin',
    QIWI: 'Qiwi',
  },
  QIWI_PAYMENT_RESPONSE_STATUS: {
    SUCCESS: 'COMPLETED',
    REJECTED: 'DECLINED',
  },
  RECENT_STEPS: {
    phone: 'LoanRequestPhone',
    personal: 'LoanRequestPersonal',
    passport: 'LoanRequestPassport',
    additional_info: 'LoanRequestJob',
    passport_photo: 'LoanRequestPhoto',
    additional_info_and_address: 'LoanRequestAdditionalInfo',
    address: 'LoanRequestAddress',
    full_info: 'LoanRequestFullInfo',
    bind_card: 'LoanRequestCard',
    password: 'LoanRequestPassword',
  },
  PASSPORT_SELFIE_REQUIREMENTS: [
    '1. Откройте паспорт на странице с фотографией.',
    '2. Поднесите в открытом виде к лицу.',
    '3. Сделайте фото.',
    'Внимание! Все данные паспорта должны быть четко видны на фотографии. Паспорт не должен закрывать лицо.',
  ],
  REQUIREMENTS_PHOTO_LIST: [
    'Необходимо добавить именно <span class="bold">фотографии</span>.<br>Скан-копии к рассмотрению нe принимаются.',
    'Данные на фотографии должны быть четко видны (без бликов, засветов, не закрывайте пальцами данные, не обрезайте края паспорта и пр.).',
    'Фотографии должны быть сделаны сегодня.',
    '<span class="bold">Не используйте редакторы</span> (в т.ч. для обрезки фотографий).',
    'Если у вас нет постоянной регистрации, в поле «Фото регистрации» приложите фотографию пустого разворота или разворота с последним местом вашей регистрации',
    'Фотографии должны быть формата <span class="bold">png</span> или <span class="bold">jpg</span>.<br> Размер фото не более <span class="bold">15 мб</span>.',
  ],
  ADDITIONAL_SERVICES: {
    INSURANCE: 'insurance',
    SMS: 'sms',
    ALL: 'all',
  },
  SMS_SENDER_ENGINE: 'sms',
  CALL_SENDER_ENGINE: 'asterisk',
  ESIA_AUTH_FAILED: 'Ошибка авторизации через ЕСИА.<br/>Попробуйте еще раз',
  SMS_SENDER_TEXT: 'Сейчас на Ваш телефон поступит смс.<br> Введите код подтверждения.',
  CALL_SENDER_TEXT: 'Сейчас на Ваш телефон поступит звонок. <br> Введите 4 последние цифры номера, с которого Вам позвонили.',
};
