import { routingLazyLoad } from '@/helpers/functions';

const resetPasswordRoutes = [
  {
    path: '/reset-password/',
    component: routingLazyLoad('ResetPasswordCheckPhone'),
    name: 'ResetPasswordPhone',
    meta: {
      title: 'Восстановление пароля',
      smallTitle: true,
    },
  },
  {
    path: '/reset-password/check',
    name: 'ResetSMS',
    component: routingLazyLoad('SmsPage'),
    props: {
      dispatchType: 'ResetPassword/SMS',
      timerType: 'reset_password',
      startOverPage: 'ResetPasswordPhone',
    },
    meta: {
      isConfirmTitle: true,
      smallTitle: true,
    },
  },
  {
    path: '/reset-password/change-password',
    name: 'NewPassword',
    component: routingLazyLoad('Password'),
    props: {
      dispatchType: 'ResetPassword/NEW_PASSWORD',
      type: 'reset_password',
    },
    meta: {
      title: 'Укажите пароль от Вашего личного кабинета',
      smallTitle: true,
    },
  },
];

export default resetPasswordRoutes;
