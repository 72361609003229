import { routingLazyLoad } from '@/helpers/functions';

const getLoanRoutes = [
  {
    path: '/get-loan/',
    component: routingLazyLoad('Cabinet'),
    meta: {
      sidebar: true,
      title: 'Взять заём',
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        component: routingLazyLoad('LoanProducts', 'Loan'),
        name: 'LoanProducts',
        meta: {
          sidebar: true,
          title: 'Взять заём',
        },
      },
      {
        path: 'signing-contract',
        name: 'SigningContract',
        component: routingLazyLoad('SigningContract'),
        meta: {
          breadcrumb: 'Подписание договора',
          sidebar: true,
          canNotGoFromUrl: true,
          title: 'Подписание договора',
        },
      },
      {
        path: 'documents',
        name: 'create:passport_photos',
        component: routingLazyLoad('ProfileDocuments', 'Profile'),
        meta: {
          steps: true,
          sidebar: true,
          title: 'Документы',
          type: 'create',
        },
      },
      {
        path: 'bank-card',
        name: 'create:bank_card',
        component: routingLazyLoad('ProfileBankCard', 'Profile'),

        meta: {
          steps: true,
          sidebar: true,
          title: 'Привязка банковской карты',
          type: 'create',
        },
      },
      {
        path: 'personal',
        name: 'create:personal_info',
        component: routingLazyLoad('ProfilePersonal', 'Profile'),
        meta: {
          steps: true,
          sidebar: true,
          title: 'Контактная информация',
          type: 'create',
        },
      },
      {
        path: 'address/',
        name: 'create:personal_address',
        component: routingLazyLoad('ProfileAddress', 'Profile'),
        meta: {
          steps: true,
          sidebar: true,
          title: 'Адрес',
          type: 'create',
        },
      },
      {
        path: 'job/',
        name: 'create:job_income',
        component: routingLazyLoad('ProfileJob', 'Profile'),
        meta: {
          steps: true,
          sidebar: true,
          title: 'Занятость и доходы',
          type: 'create',
        },
      },
      {
        path: 'car-info/',
        name: 'create:car_info',
        component: routingLazyLoad('ProfileCarInfo', 'Profile'),
        meta: {
          steps: true,
          sidebar: true,
          title: 'Информация о машине',
          type: 'create',
          canNotGoFromUrl: true,
        },
      }, {
        path: 'car-doc-photos/',
        name: 'create:car_doc_photos',
        component: routingLazyLoad('ProfileCarDocs', 'Profile'),
        meta: {
          steps: true,
          sidebar: true,
          title: 'Документы на машину',
          type: 'create',
          canNotGoFromUrl: true,
        },
      },
      {
        path: 'full-info/',
        name: 'create:full_info_profile',
        component: routingLazyLoad('ProfileFullInfo', 'Profile'),
        meta: {
          steps: true,
          sidebar: true,
          title: 'Проверка данных',
          type: 'create',
        },
      },
      {
        path: 'success/',
        name: 'SuccessCreateLoan',
        component: routingLazyLoad('SuccessCreateLoan'),
        meta: {
          canNotGoFromUrl: true,
          sidebar: true,
          title: 'Заявка на заём',
        },
      },
      {
        path: ':name/',
        name: 'LoanOptions',
        component: routingLazyLoad('LoanOptions', 'Loan'),
        meta: {
          sidebar: true,
          title: false,
        },
      },
    ],
  },
];

export default getLoanRoutes;
