<template>
  <div class="refresh-page-modal">
    <div class="refresh-page-modal__wrapper">
      <div class="refresh-page-modal__content">
        <span>Обновили сайт. Для продолжения работы нажмите</span>
        <button
          class="btn btn-red"
          @click="refreshPage"
        >
          Продолжить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    refreshPage() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.refresh-page-modal {
  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    z-index: 999999999;
    background-color: rgb(0 0 0 / 38%);
  };
  &__content {
    padding: 24px 32px;
    background-color: #fff;
    box-shadow: $default-shadow;
    border-radius: $default-border-radius;
    margin: auto;
    text-align: center;
    span {
      display: block;
      font-size: 20px;
      margin-bottom: 30px;
    }
  }
}
</style>
