export default {
  methods: {
    showHelperModal(text) {
      this.$modal.show({
        template: '<div class="modal">'
          + '<div class="modal__desc" v-html="text"></div>'
          + '</div>',
        props: ['text'],
      }, {
        text,
      }, {
        classes: 'v-modal--text',
        height: 'auto',
        adaptive: true,
        maxWidth: 600,
      });
    },
    showImageModal(src) {
      this.$modal.show({
        template: `
           <div class="photo-field__full">
              <fai :icon="['fas', 'times']" @click="$emit('close')"></fai>
              <img :src="src"/>
           </div>
        `,
        props: ['src'],
      }, {
        src,
      }, {
        classes: 'v-modal--photo',
        height: 'auto',
        width: '600',
        adaptive: true,
        maxWidth: 600,
      });
    },
  },
};
