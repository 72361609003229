import moment from 'moment';
import { mapGetters } from 'vuex';
import modifiers from '@/helpers/mixins/modifiers';

export default {
  mixins: [modifiers],
  data() {
    return {
      countdownTimer: null,
      countdownTimerWorking: true,
      withSeconds: false,
    };
  },
  computed: {
    ...mapGetters({
      isBorrowerDataEmpty: 'isBorrowerDataEmpty',
    }),

    isNewLoanBlocked() {
      this.countdown(this.borrowerData?.datetime_blocked, this.withSeconds);
      return ((!this.borrowerData?.get_loan && this.borrowerData?.datetime_blocked)
                || this.countdownTimer) && this.countdownTimerWorking;
    },

    isGetLoanSteps() {
      return this.$route.meta.steps;
    },
  },
  mounted() {
    if (!this.isBorrowerDataEmpty) {
      this.countdown(this.borrowerData.datetime_blocked && this.isGetLoanSteps);
    }
  },
  methods: {
    countdown(time, withSeconds = false) {
      const eventTime = moment(time, 'YYYY-MM-DD HH:mm:ss').subtract(3, 'hours');
      const currentTime = moment();
      const currentTimeWithTimezone = currentTime.subtract(currentTime.utcOffset(), 'minutes');
      const diffTime = eventTime - currentTimeWithTimezone;
      let duration = moment.duration(diffTime, 'milliseconds');
      const interval = 1000;

      const getTime = () => {
        duration = moment.duration(duration.asMilliseconds() - interval, 'milliseconds');

        let d = parseInt(moment.duration(duration).asDays(), 10).toString();
        let h = moment.duration(duration).hours().toString();
        let m = moment.duration(duration).minutes().toString();
        let s = moment.duration(duration).seconds().toString();
        d = d.length === 1 ? `0${d}` : d;
        h = h.length === 1 ? `0${h}` : h;
        m = m.length === 1 ? `0${m}` : m;
        s = s.length === 1 ? `0${s}` : s;
        // Доделать склонение секунд часов и т.д.
        if (+d <= 0 && +h <= 0 && +m <= 0) {
          this.countdownTimer = '1 минуту';
          this.countdownTimerWorking = true;
        } else if (+s >= 0) {
          this.countdownTimer = `${d} ${this.dateLabelConverter(+d)} ${h} ${this.dateLabelConverter(+h, ['час', 'часа', 'часов'])} ${m} ${this.dateLabelConverter(+m, ['минуту', 'минуты', 'минут'])}${withSeconds ? ` ${s} ${this.dateLabelConverter(+s, ['секунду', 'секунды', 'секунд'])}` : ''}`;
          this.countdownTimerWorking = true;
        } else {
          this.countdownTimer = null;
          this.countdownTimerWorking = false;
        }
      };

      // if time to countdown
      if (diffTime > 0 && !this.borrowerData?.active_loan) {
        getTime();
        setInterval(() => {
          getTime();
        }, interval);
      } else {
        this.countdownTimerWorking = false;
      }
    },
  },
};
