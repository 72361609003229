<template>
  <div
    ref="wrapper"
    class="notification"
    :class="'notification_theme_' + theme"
  >
    <template v-if="accordeon">
      <div class="notification__wrapper">
        <v-expansion-panel>
          <v-expansion-panel-content>
            <template #header>
              <div class="notification__title">
                {{ title }}
              </div>
            </template>
            <div class="notification__text">
              <slot />
            </div>
          </v-expansion-panel-content>
          <div
            ref="icon"
            class="notification-icon__wrapper"
            :class="{'notification-icon__wrapper_float': floatIcon}"
          >
            <template v-if="theme === 'error'">
              <div class="notification-icon__text">
                Внимание!
              </div>
              <div class="notification-icon__image">
                <img src="@/assets/images/icons/unverified.svg">
              </div>
            </template>
            <template v-if="theme === 'info'">
              <div class="notification-icon__text">
                Сообщение
              </div>
              <div class="notification-icon__image">
                <img src="@/assets/images/icons/info-color.svg">
              </div>
            </template>
          </div>
        </v-expansion-panel>
      </div>
    </template>
    <template v-else>
      <div class="notification__wrapper">
        <div class="notification__title">
          {{ title }}
        </div>
        <div class="notification__text">
          <slot />
        </div>
        <div
          ref="icon"
          class="notification-icon__wrapper"
          :class="{'notification-icon__wrapper_float': floatIcon}"
        >
          <template v-if="theme === 'error'">
            <div class="notification-icon__text">
              Внимание!
            </div>
            <div class="notification-icon__image">
              <img src="@/assets/images/icons/unverified.svg">
            </div>
          </template>
          <template v-if="theme === 'info'">
            <div class="notification-icon__text">
              Сообщение
            </div>
            <div class="notification-icon__image">
              <img src="@/assets/images/icons/info-color.svg">
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BaseNotification',
  props: {
    theme: {
      type: String,
      default: 'error',
    },
    accordeon: Boolean,
    floatIcon: Boolean,
    title: {
      type: String,
      default: '',
    },
    fullWidth: Boolean,
  },
  mounted() {
    document.addEventListener('scroll', this.iconHandler);
  },
  unmounted() {
    document.removeEventListener('scroll', this.iconHandler);
  },
  methods: {
    iconHandler() {
      const displayWidth = document.body.clientWidth;
      if (!this.floatIcon || displayWidth > 1024) return;
      const getCoords = elem => {
        if (elem) {
          const box = elem.getBoundingClientRect();

          // eslint-disable-next-line no-restricted-globals
          return pageYOffset - box.top;
        }
      };
      const wrapper = document.querySelector('.wrapper');
      const scrollTo = () => {
        this.$vuetify.goTo(this.$refs.wrapper, {
          offset: 75,
        });
      };
      const mathCoof = wrapper.classList.contains('hidden-message') ? 92 : 192;
      const header = document.querySelector('.header');
      const headerTop = header.style.top.match(/\d+/);
      const elem = this.$refs.icon;
      const top = getCoords(elem);

      if (top >= 100) {
        elem.classList.add('fixed');
        elem.style.top = `${mathCoof + (-+headerTop)}px`;
        elem.addEventListener('click', scrollTo);
      } else if (elem && elem.classList.contains('fixed')) {
        elem.classList.remove('fixed');
        elem.removeEventListener('click', scrollTo);
        elem.style.top = 'auto';
      }
    },
  },
};
</script>

<style lang="scss">
   .notification {
     &__wrapper {
       padding: 24px 32px;
       box-shadow: 0 0 15px rgba(1, 23, 40, 0.1);
       border: 1px solid #000;
       background-color: #fff;
       border-radius: 16px;
       font-size: 18px;
       max-width: 804px;
       position: relative;
       z-index: 7;
     }

     &_theme {
       &_error {
         .notification__wrapper {
           border-color: $color-orange;
         }
       }

       &_info {
         .notification__wrapper {
           border-color: $color-blue;
         }

         .notification__title {
           color: $color-blue;
         }
       }
     }

     &__title {
       font-weight: 600;
       margin-bottom: 10px;
     }

     &-icon {
       display: flex;

       &__image {
         width: 24px;
         height: 24px;
       }

       &__text {
         display: none;
         margin-right: 5px;
       }

       &__wrapper {
         position: absolute;
         right: 4px;
         bottom: 4px;
         height: 24px;
         display: flex;
         align-items: center;

         &_float {
           cursor: pointer;
         }

         &.fixed {
           position: fixed;
           top: 16px;
           right: 28px;
           height: 33px;
           background-color: #fff;
           border-radius: 16px;
           box-shadow: 0 0 15px rgba(1, 23, 40, 0.1);
           padding: 5px 10px;

           @media screen and (max-width: 580px) {
             right: 11px;
           }

           .notification-icon__text {
             display: inline-block;
           }
         }

         img {
           width: 100%;
         }
       }
     }

     .v-expansion-panel {
       box-shadow: none;

       &__header {
         padding: 0;
       }

       &__body {
         margin-top: 10px;
       }

       .notification__title {
         margin-bottom: 0;
       }
     }
   }
</style>
