import {
  CHECK_PHONE,
  SEND_SMS,
  REGISTRATION_FINAL,
  CHECK_PASSPORT,
} from '@/helpers/js/urls';
import router from '@/router/Router';
import store from '../index';

const state = {
  smsErrorTimer: null,
};
const getters = {};
const mutations = {
  SET_ERROR_TIMER(state, data) {
    state.smsErrorTimer = data;
  },
};
const actions = {
  CHECK_PASSPORT: async ({
    rootState,
    dispatch,
  }, data) => {
    const response = await dispatch('API', {
      method: 'post',
      url: CHECK_PASSPORT,
      body: data,
    }, { root: true });
    if (rootState.errors.length > 0) return;
    const resData = response.data.data;

    if (resData.reg_check) {
      if (!localStorage.getItem('registrationTimer')) {
        localStorage.setItem('registrationTimer', new Date().getTime());
      }

      router.push({
        name: 'SendSMS',
      });
    }
  },
  CHECK_PHONE: async ({
    commit,
    dispatch,
    rootState,
  }, data) => {
    try {
      const response = await dispatch('API', {
        method: 'post',
        url: CHECK_PHONE,
        body: data,
        preloader: true,
        showEmptyErrors: false,
      }, { root: true });

      if (rootState.errors.length > 0) {
        for (const error in rootState.errors) {
          if (rootState.errors[error].hasOwnProperty('access_key')) {
            commit('ACCESS_KEY', rootState.errors[error].access_key, { root: true });
          }
        }
        return;
      }

      const responseData = response?.data?.data;
      const { access_key } = responseData;

      if (access_key) {
        commit('Auth/ACCESS_KEY', access_key, { root: true });
      }

      localStorage.removeItem('phone');

      // Если номер не найден редирект на СМС
      if (responseData?.reg_check) {
        localStorage.setItem('registrationTimer', new Date().getTime());
        await router.push({
          name: 'SendSMS',
        });
        // Если номер найден, но Дата рождения введена 3 раза неправильно редирект на проверку пасспорта
      } else if (responseData?.hasOwnProperty('reg_passport_check')) {
        await router.push({
          name: 'CheckPassport',
        });
      }
    } catch (rej) {
      if (rej?.response?.status === 429) {
        commit('SET_ERROR_TIMER', rej?.response?.data?.data?.message[0]);
        commit('CLEAR_ERRORS', {}, { root: true });

        return;
      }

      if (rej?.response?.data?.data?.access_key) {
        commit('Auth/ACCESS_KEY', rej?.response?.data?.data?.access_key, { root: true });
      }
      if (rej?.response?.data?.data?.phone?.code === 'user_exist') {
        await router.push({
          name: 'Login',
          params: {
            userExistModal: true,
          },
        });
      }
      return rej.response.data;
    }
  },
  SMS: async ({
    dispatch,
    rootState,
  }, data) => {
    await dispatch('API', {
      method: 'post',
      url: SEND_SMS,
      body: data,
    }, { root: true });

    if (rootState.errors.length > 0) {
      for (const error in rootState.errors) {
        if (rootState.errors[error].code === 'sms_passed') {
          router.push({
            name: 'CreatePassword',
          });
        } else if (rootState.errors[error].code === 'expired_sms_code') {
          router.push({
            name: 'Registration',
          });
        }
      }
    } else {
      if (localStorage.getItem('registrationTimer')) {
        localStorage.removeItem('registrationTimer');
      }
      router.push({
        name: 'CreatePassword',
      });
    }
  },
  FINAL: async ({
    dispatch,
    rootState,
  }, data) => {
    const response = await dispatch('API', {
      method: 'post',
      url: REGISTRATION_FINAL,
      body: data,
    }, { root: true });
    if (rootState.error) return;
    const { token } = response.data.data;
    store.dispatch('Auth/AUTH_SUCCESS', token).then(() => {
      store.dispatch('Auth/SUCCESS_ENTRY');
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
