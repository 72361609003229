<template>
  <div class="footer-contacts">
    <v-row
      v-if="!$vuetify.breakpoint.lgAndUp"
      align="center"
      class="d-block"
    >
      <v-col class="py-0">
        <v-row
          align="center"
          class="d-block"
        >
          <v-col class="white--text text-no-wrap pb-0 pt-0">
            Горячая линия:
          </v-col>
          <v-col>
            <div class="d-flex">
              <div class="d-block pr-6">
                <a
                  :href="`tel:${$consts.COMPANY_PHONE}`"
                  class="white--text font-weight-medium-plus text-no-wrap link link-hover-red d-block"
                >{{ $consts.COMPANY_PHONE }}</a>
                <a
                  class="white--text font-weight-medium-plus link link-hover-red d-block"
                  href="mailto:help@dengabank.ru"
                >{{ $consts.COMPANY_HELP_EMAIL }}</a>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="auto">
        <base-button
          :to="{name: 'Help'}"
          class="link justify-start"
          color="primary"
          min-width="126"
          rounded="rounded"
        >
          <v-icon class="pr-3">
            {{ mdiHelpCircleOutline }}
          </v-icon>Помощь
        </base-button>
      </v-col>
      <v-col md="auto">
        <base-button
          :to="{name: 'Map'}"
          color="primary"
          rounded="rounded"
        >
          <v-icon class="pr-3">
            {{ mdiMapMarker }}
          </v-icon>Адреса отделений
        </base-button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiHelpCircleOutline, mdiMapMarker } from '@mdi/js';
import BaseButton from '../base-components/BaseButton.vue';

export default {
  name: 'Contacts',
  components: {
    BaseButton,
  },
  data() {
    return {
      mdiHelpCircleOutline,
      mdiMapMarker,
    };
  },
};
</script>

<style lang="scss">
</style>
