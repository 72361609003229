<template>
  <div
    :class="{ 'form-field--error': hasErrors}"
    v-bind="$attrs"
    class="form-field"
  >
    <slot :errors="activeErrorMessages" />
  </div>
</template>

<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

export default {
  name: 'FormField',
  mixins: [singleErrorExtractorMixin],
};
</script>

<style lang="scss">
.form-field {
  .v-text-field {
    padding-top: 0;
    margin-top: 0;

    .v-input__slot {
      height: 56px;
      border: 1px solid transparent;
      padding: 0 32px;
      box-shadow: $default-shadow;
      background: $color-white;

      &:before, &:after {
        display: none;
      }

      label, input {
        color: $color-dark-blue !important;
      }

      input {
        letter-spacing: 0.05em;
        background: transparent;
      }

      label {
        opacity: 0.5 !important;
        z-index: 2;
      }
    }

    &.v-text-field--solo {
      .v-input__slot {
        box-shadow: none !important;
      }
    }

    &.v-input--is-disabled {
      .v-input__slot {
        background: $color-disabled;
      }
    }

    .v-text-field__details {
      padding: 0 32px;
      margin-bottom: 8px;
    }

    &.error--text {
      .v-input__slot {
        border-color: $color-orange;
      }
    }

    &--outlined {
      .v-input__slot {
        box-shadow: $default-shadow;
      }

      &.v-input--is-disabled {
        .v-input__slot {
          background: $color-disabled !important;
        }

        input {
          background: transparent;
        }
      }
    }
  }

  .v-textarea {
    .v-input__slot {
      height: auto;
      padding: 16px 32px;
    }
    .v-label--active {
      transform: translateY(-18px) scale(0.75);
    }
  }
}
</style>
